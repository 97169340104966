<template>
    <div id="content-subtitles">
        <div>
            <button class="uk-button uk-button-primary uk-float-right uk-margin-small-bottom" href="#content-subtitles-modal" uk-toggle>
                <span uk-icon="plus-circle"></span> {{ $t('Pages.Content.Subtitles.AddSubtitle') }}
            </button>
            <br>
            <baseTable v-if="contentSubtitles.length>0" :tableHeaders="tableHeaders">
                <tr
                v-for="(subtitle, i) in contentSubtitles" 
                :key="i"
                class="uk-items-center table-row">
                    <td><span>{{ subtitle.language }}</span></td>
                    <td class="uk-text-truncate">{{ subtitle.name }}</td>
                    <td class="uk-text-truncate"><span>{{ subtitle.file.media.title}} </span> </td>
                    <td class="uk-text-center">
                        <div style="cursor: pointer" uk-icon="icon: pencil; ratio: 1" class="trash-icon" @click.stop="editSubtitle( subtitle )"/>
                        <span v-if="deletingList.includes( subtitle )" class="uk-margin-left" uk-spinner="ratio: 0.6" />
                        <div v-else style="cursor: pointer" uk-icon="icon: trash; ratio: 1" class="trash-icon uk-margin-left" @click.stop="deleteSubtitle( subtitle )" />
                    </td>
                </tr>
            </baseTable>
            <span v-else class="uk-text-meta uk-text-large">{{$t('Generic.Messages.NoData')}}</span>
            <div id="content-subtitles-modal" container="#content-subtitles" uk-modal="bg-close: false; esc-close: true; stack: true">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-left">{{ edit ? $t('Pages.Content.Subtitles.EditSubtitle') : $t('Pages.Content.Subtitles.AddSubtitle') }}</h2>
                    </div>
                    <div class="uk-modal-body">
                        <div class="uk-clearfix">
                            <button class="uk-button uk-button-primary uk-align-left uk-margin-remove-bottom" href="#subtitles-media-picker-modal" uk-toggle @click="selectedFileError = false">
                                {{ edit ? $t('Pages.Content.Subtitles.EditSubtitlesFile') : $t('Pages.Content.Subtitles.AddSubtitlesFile') }}
                            </button>
                            <br>
                            <p v-if="selectedFileError" class="uk-text uk-text-left uk-text-danger uk-margin-remove-bottom">{{selectedFileError}} <span uk-icon="icon: warning"></span></p>
                            <p v-else class="uk-text-meta uk-text-left uk-margin-small-bottom"> {{selectedFile.title}} </p>
                        </div>
                        <div class="uk-margin">
                         <span uk-grid >
                <label class="uk-form-label uk-width-1-2 uk-text-left">{{$t('Languages.Language') }} <span class="uk-text-danger">*</span></label>
                <span v-if="selectedFileLangError" class="uk-text-right uk-text-danger uk-width-1-2">{{ selectedFileLangError }} <span uk-icon="icon: warning"></span></span>
                </span>
                        <div uk-form-cutom="target: true" class="uk-form-custom">
                            <select class="uk-select uk-margin-small-top" @click="selectedFileLangError = false" id="content-lang" v-model="subtitle.lang" @input="onLanguageInput">
                                <option
                                    v-for="(lang, i) in availableLanguages"
                                    :key="i"
                                    :value="lang"
                                >
                                    {{ $t( `Languages.isoLanguageLabels.${lang}` ) }}
                                </option>
                            </select>
                            <span class="uk-select uk-width-xlarge uk-text-meta uk-text-left" id="content-lang">{{ subtitle.lang ? $t( `Languages.isoLanguageLabels.${subtitle.lang}` ) : $t('Languages.SelectLanguage') }}</span>
                        </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label uk-align-left uk-margin-remove-bottom">{{ $t('Pages.Content.Subtitles.ShowInAppAs') }} <span class="uk-text-danger">*</span></label>
                            <div class="uk-form-controls">
                                <div class="uk-inline uk-width-1-1">
                                    <input class="uk-input" 
                                    type="text"
                                    v-model.trim="subtitle.shownAs"
                                    autocomplete="off"
                                    @keyup="validateShownAs()"
                                    :placeholder="$t('Pages.Content.Subtitles.ShowInAppAs')" />
                                    <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="shownAsError" uk-icon="icon: warning"></span>
                                    <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="shownAsError" >{{shownAsError}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button class="uk-button uk-button-default uk-modal-close uk-margin-right" @click="resetForm">{{ $t('Actions.Cancel') }}</button>
                        <button v-if="!edit && !loading" class="uk-button uk-button-primary" @click="createSubtitle()">{{ $t('Actions.Save') }}</button>
                        <button v-else-if="edit && !loading" class="uk-button uk-button-primary" @click="editSubtitleFileorLang()">{{ $t('Actions.Save') }}</button>
                        <button v-if="loading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
                    </div>
                </div>
            </div>
            <div id="subtitles-media-picker-modal" uk-modal="bg-close: false; esc-close: true;">
                <div class="uk-modal-dialog uk-width-3-4 media-picker-modal">
                    <ModalMediaPicker media-class-filter="SUBTITLES" @cancel="closeModal()" @select="selectedSubtitleMedia( $event )" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SupportedLanguages } from '@/utils/content';
import Notification from '@/components/util/Notification';
import BaseTable from '@/components/tables/BaseTable.vue';

function getLanguageLabel ( lang ) {
    return this.$t( `Languages.isoLanguageLabels.${lang}` );
}

const subtitleListToMediaReference = subtitles => subtitles
    .map( sub => ({ id: sub.file?.media?.id }) );

export default {
    name: 'ContentSubtitles',
    props: {
        content: { type: Object, required: true }
    },
    components: { ModalMediaPicker: () => import('@/components/modals/ModalMediaPicker.vue'), BaseTable },
    data () {
        return {
            SupportedLanguages,
            subtitle: {
                lang: '',
                shownAs: '',
            },
            selectedFile: '',
            edit: false,
            subtitles: ( this.content.ref?.allSubtitles || []).slice(),
            deletingList: [],
            selectedSubtitle: {},
            loading: false,
            languageChanged: false,
            selectedFileError: false,
            selectedFileLangError: false,
            shownAsError: false
        }
    },
    beforeDestroy () {
        if(window.UIkit.modal('#subtitles-media-picker-modal'))
            window.UIkit.modal('#subtitles-media-picker-modal').$destroy(true);
    },
    watch: {
        'subtitle.lang': {
            handler ( newVal ) {
                if ( newVal && this.languageChanged ) {
                    this.subtitle.shownAs = getLanguageLabel.call( this, newVal );
                }
            },
            deep: true,
            immediate: true
        },
        'subtitle.shownAs': {
            handler () {
                this.validateShownAs()
            },
            deep: true,
            immediate: true
        }
    }, 
    computed: {
        contentSubtitles () {
            return this.content.ref?.allSubtitles;
        },
        availableLanguages () {
            return SupportedLanguages.filter(cl => !this.contentSubtitles?.find(l => cl == l.language));
        },
        tableHeaders () {
            return [
            {
                name: this.$t('Languages.Language'),
                class: 'uk-width-medium'
            }, {
                name: this.$t('Generic.Labels.Title'), 
                class: 'uk-width-large '
            },
            {
                name: 'File name', 
                class: 'uk-width-2xlarge uk-preserve-width'
            },
            {
                name: this.$t('Actions.Actions'), 
                class: 'uk-width-small uk-text-center'
            }]
        }
    },
    methods: {
        closeModal () {
            window.UIkit.modal('#subtitles-media-picker-modal').hide();
            window.UIkit.modal('#content-subtitles-modal').show();
        },
        selectedSubtitleMedia ( media ) {
            this.selectedFile = media;
            this.closeModal();
        },
        validateForm () {
            let isValid = true
            if (!this.selectedFile) {
                isValid = false
                this.selectedFileError = this.$t( 'Pages.Content.Subtitles.MandatoryField');
            }
             if (!this.subtitle.lang) {
                isValid = false
                this.selectedFileLangError = this.$t( 'Pages.Content.Subtitles.MandatoryField');
            }
            if (!this.subtitle.shownAs) {
                isValid = false
                this.shownAsError = this.$t( 'Pages.Content.Subtitles.MandatoryField')
            }
            let subtitles = subtitleListToMediaReference(this.contentSubtitles)
            if ( subtitles.length && subtitles.filter( s => s.id === this.selectedFile.id ).length ) {
                isValid = false
                this.selectedFileError = this.$t( 'Pages.Content.Subtitles.FileSelected');
            }
            return isValid
        },
        async createSubtitle () {
            if (!this.validateForm()) return false
            let subtitles = subtitleListToMediaReference(this.contentSubtitles)
            subtitles.push({id: this.selectedFile.id})
            this.createOrEditDispatch(subtitles); 
        },
        async createOrEditDispatch ( subtitles ) {
            this.loading = true;
            const response = await this.$store.dispatch('addContentSubtitles', { content: this.content.id, subtitles: subtitles });
            await this.$store.dispatch('setMediaData',{ media: this.selectedFile.id, key: 'subtitles.displayName', value: this.subtitle.shownAs })
            await this.$store.dispatch('setMediaData',{ media: this.selectedFile.id, key: 'subtitles.lang', value: this.subtitle.lang })
            await this.$store.dispatch('getContentItem',{contentId: this.content.id});
            this.loading = false;
            if (response?.updateVodSettings?.content?.id) {
                if(!this.edit)
                    Notification.showNotification(this.$t( 'Generic.Messages.Successful'), this.$t( 'Pages.Content.Subtitles.AddSubtitleSuccess'));
                else
                    Notification.showNotification(this.$t( 'Generic.Messages.Successful'), this.$t( 'Pages.Content.Subtitles.EditSubtitleSuccess'));
            } else {
                Notification.showNotification(this.$t( 'Generic.Messages.UnSuccessful'), response?.message || response, 'error');
            }
            window.UIkit.modal('#content-subtitles-modal').hide();
            this.edit = false;
            this.resetForm();
        },
        async deleteSubtitle ( subtitle ) {
            // TODO Remove key,value from data in media
            window.UIkit.modal.confirm(this.$t( 'DeleteModal.ConfirmDelete', { name: subtitle.name }), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }}).then( async () => {
                this.deletingList.push(subtitle)
                const index = this.contentSubtitles.map( item => item.file ).findIndex( i => i.media.id === subtitle.file.media.id )
                const subtitlesCopy = this.contentSubtitles;
                subtitlesCopy.splice(index,1);
                let subtitles = subtitleListToMediaReference(subtitlesCopy)
                const response = await this.$store.dispatch('addContentSubtitles', { content: this.content.id, subtitles: subtitles });
                await this.$store.dispatch('getContentItem',{ contentId: this.content?.id });
                if ( response ) {
                    this.deleteLangRef( subtitle );
                    Notification.showNotification(this.$t( 'DeleteModal.Removed'), this.$t( 'DeleteModal.HasBeenRemoved', { name: subtitle.name }));
                } else {
                    Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), this.$t( 'DeleteModal.CouldNotRemove', { name: subtitle.name }), false);
                }
            }, () => {
                this.deleteLangRef( subtitle );
            });
        },
        deleteLangRef ( subtitle ) {
            this.deletingList.forEach( s => {
                if( s == subtitle )
                this.deletingList.splice(s,1);
            });
        },
        editSubtitle ( subtitle ) {
            this.edit = true;
            this.languageChanged = false;
            this.subtitle.lang = subtitle.language;
            this.subtitle.shownAs = subtitle.name;
            this.selectedFile = subtitle.file.media;
            this.selectedSubtitle = subtitle;
            window.UIkit.modal('#content-subtitles-modal').show();
        },
        editSubtitleFileorLang () {
            if (!this.subtitle.shownAs) {
                this.shownAsError = this.$t( 'Pages.Content.Subtitles.MandatoryField')
                return
            }
            const index = this.contentSubtitles.map( item => item.file ).findIndex( i => i.media.id === this.selectedSubtitle.file.media.id )
            this.subtitles = this.contentSubtitles;
            this.subtitles.splice(index,1);
            let subtitles = subtitleListToMediaReference(this.subtitles)
            subtitles.push({id: this.selectedFile.id})
            this.createOrEditDispatch( subtitles );
        },
        resetForm () {
            this.subtitle.lang = '',
            this.subtitle.shownAs = '',
            this.selectedFile = '';
            this.edit = false;
            this.selectedFileError = '';
            this.selectedFileLangError = '';
            this.shownAsError = '';
        },
        onLanguageInput () {
            this.languageChanged = true;
        },
        validateShownAs () {
            if (this.subtitle.shownAs) {
                this.shownAsError = false
            }
        }
    }
};
</script>