<template>
<div id="content-language">
  <div>
    <div class="uk-float-right">
      <button class="uk-button uk-button-primary add-lang-btn uk-margin-small-bottom" href="#content-language-modal" uk-toggle>
        <span uk-icon="plus-circle"></span> {{ $t('Languages.AddLanguage') }}
      </button>
    </div>
  </div>
  <baseTable :tableHeaders="tableHeaders" class="uk-margin-small-top">
    <tr
      v-for="(lang, i) in sortedLanguages"
      :key="lang.language + i"
      class="uk-items-center table-row">
        <td><span>{{ langTitle( lang ) }}</span></td>
        <td class="uk-text-truncate uk-width-medium"><span>{{ lang.title }}</span></td>
        <td class="uk-text-truncate uk-width-2xlarge uk-preserve-width">{{ lang.description }}</td>
        <td :class="{'disable': lang.isPrimary}">
          <ToggleButton :disabled="isFetching" class="uk-margin-small-top uk-float-right" :value="lang.isPrimary" @toggle="setPrimary(lang, $event)"/>
        </td>
        <td class="uk-text-center">
          <div uk-icon="icon: pencil; ratio: 1" class="trash-icon" @click.stop="clickEdit( lang )"/>
          <span v-if="deletingList.includes( lang )" class="uk-margin-left" uk-spinner="ratio: 0.6" />
          <div v-else uk-icon="icon: trash; ratio: 1" class="trash-icon uk-margin-left" @click.stop="deleteLanguage( lang )" />
        </td>
      </tr>
    </baseTable>
  <div id="content-language-modal" container="#content-language" uk-modal="bg-close: false; esc-close: false; stack: true">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-left">{{ editLanguage ? $t('Languages.EditLanguage') : $t('Languages.AddLanguage') }}</h2>
        </div>
        <div class="uk-modal-body uk-padding-remove-top uk-padding-remove-bottom  uk-flex uk-flex-column">
          <form id="new-language" class="uk-form-stacked uk-text-left" @submit.prevent="onSubmit();">
              <button class="uk-modal-close-default" type="button" uk-close @click="resetForm()"></button>
              <div class="uk-margin">
                <span uk-grid >
                <label class="uk-form-label uk-width-1-2">{{$t('Languages.Language') }} <span class="uk-text-danger">*</span></label>
                  <span v-if="form.languageError" class="uk-text-right uk-text-danger uk-width-1-2">{{$t('Generic.Errors.MandatoryField')}} <span uk-icon="icon: warning"></span></span>
                </span>
                <div uk-form-cutom="target: true" class="uk-form-custom" :class="{ 'invalid-border': form.languageError }">
                    <select class="uk-select uk-width-xlarge" @click="form.languageError = false" :class="{ 'uk-disabled': editLanguage }" id="content-lang" v-model.trim="form.language">
                      <option
                        v-for="(lang, i) in editLanguage?SupportedLanguages:availableLanguages"
                        :key="i"
                        :value="lang"
                      >
                        {{ $t( `Languages.isoLanguageLabels.${lang}` ) }}
                      </option>
                    </select>
                     <span class="uk-select uk-width-xlarge uk-text-meta" :class="{ 'uk-disabled': editLanguage }" id="content-lang">{{ form.language ? $t( `Languages.isoLanguageLabels.${form.language}` ) : $t('Languages.SelectLanguage') }}</span>
                  </div>
              </div>
              <div class="uk-margin">
                  <label class="uk-form-label">{{$t('Generic.Labels.Title') }} <span class="uk-text-danger">*</span></label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input class="uk-input" type="text"
                          :class="{ 'invalid-border': editLanguage ? !form.title || form.titleError : form.titleError}"
                          v-model.trim="form.title"
                          autocomplete="off"
                          required
                          @input="validateTitle($event.target.value)"
                          :placeholder="$t('Generic.Labels.Title')" />
                          <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="editLanguage ? !form.title || form.titleError : form.titleError" uk-icon="icon: warning" ></span>
                          <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="editLanguage ? !form.title || form.titleError : form.titleError" >{{form.titleError || $t('Generic.Errors.Min3Chars')}}</span>
                    </div>
                  </div>
              </div>
              <div class="uk-margin">
                  <label class="uk-form-label">{{ $t('Languages.Composer') }}</label>
                  <div class="uk-form-controls">
                      <input class="uk-input" 
                          type="text"
                          v-model.trim="form.headline"
                          autocomplete="off"
                          :placeholder="$t('Languages.Composer')" />
                  </div>
              </div>
              <div v-if="content.type !== 'AD'" class="uk-margin">
                  <label class="uk-form-label">{{ $t('Languages.Publisher') }}</label>
                  <div class="uk-form-controls">
                      <input class="uk-input" 
                          type="text"
                          v-model.trim="form.attribution"
                          autocomplete="off"
                          :placeholder="$t('Languages.Publisher')" />
                  </div>
              </div>
              <div class="uk-margin">
                  <label class="uk-form-label">{{ $t('Languages.Description') }}</label>
                  <div class="uk-form-controls">
                      <textarea class="uk-textarea" 
                      rows="2"
                      type="text"
                      v-model.trim="form.description"
                      autocomplete="off"
                      :placeholder="$t('Languages.Description')" />
                  </div>
              </div>
              <div v-if="editLanguage&&editLanguage.isPrimary" class="uk-margin" >
                <div class="uk-width-1-1">
                  <label class="uk-form-label">{{ $t('Pages.Content.Labels.Tags') }}</label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input 
                        class="uk-input"
                        type="text"
                        v-model.trim="tag"
                        autocomplete="off"
                        :placeholder="$t('Pages.Content.Placeholders.Tags')"
                        @focus="errors.tag = ''"
                        @keyup.enter="addTag()" />
                        <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.tag" uk-icon="icon: warning" ></span>
                        <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.tag" > {{errors.tag}}</span>
                    </div>
                  </div>
                    <div v-for="tag in form.tags" :key="tag" class="tag-item uk-margin-small-top uk-margin-small-right" style="max-width:100%">
                    <div class="label uk-margin-small-left uk-margin-small-right  uk-text-truncate" style="max-width: 87%" @click="$emit('click')" >
                        <span :uk-tooltip="tag" :key="tag"> {{ tag }} </span>
                    </div>
                    <span class="close uk-margin-auto-vertical uk-margin-small-right" style="cursor: pointer;" uk-icon="icon: close;ratio: 0.8" @click="deleteTag(tag)"></span>
                    </div>
                </div>
                <div class="uk-flex uk-flex-between uk-margin-small-top">
                <div class="uk-width-1-2">
                  <label class="uk-form-label"> {{ $t('Pages.MediaLibrary.Labels.ProjectionType') }} </label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-medium">
                      <select class="uk-select uk-width-medium"  v-if="content.type==='VOD' || content.type==='STREAM'" v-model="form.projection">
                        <option v-for="type in ProjectionType.values" :key="type" :value="type">{{ $t( `enums.ProjectionType.${type}` ) }}</option>
                      </select>
                      <select v-else @click.stop="" :uk-tooltip="$t('Pages.Content.NotSupportedProjection')" style="cursor: not-allowed"  class="uk-select uk-width-small">
                    </select>
                    </div>
                  </div>
                  </div>
                <div class="uk-width-small uk-flex-right">
                  <label class="uk-form-label uk-width-medium">{{ $t('Pages.Content.Labels.VideoCategoryType') }}</label>
                    <select class="uk-select uk-width-small" v-if="content.type!=='AD'" v-model="form.category">
                      <option v-for="category in EntertainmentCategoryList.slice().sort()" :key="category"
                        :value="category">{{ $t(`Pages.Content.VideoCategoryTypes.${category}`)}}</option>
                    </select>
                    <select v-else @click.stop="" :uk-tooltip="$t('Pages.Content.NotSupportedCategory')" style="cursor: not-allowed"  class="uk-select uk-width-small">
                    </select>
                </div>
                </div>
              </div>
          </form>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-right" @click="resetForm()">{{ $t('Actions.Cancel') }}</button>
          <button v-if="!isLoading" class="uk-button uk-button-primary" @click="onSubmit()">{{ $t('Actions.Save') }}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
    </div>
    <div id="info-language-modal" uk-modal="bg-close: false; esc-close: false;">
      <div class="uk-modal-dialog uk-modal-body">
          <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-text-warning" uk-icon="icon: info; ratio: 2" />
          <p class="uk-text-meta">{{ $t('DeleteModal.LanguageCannotBeDeleted') }}</p>
      </div>
    </div>
</div>
</template>

<script>
import { SupportedLanguages, EntertainmentCategoryList } from '@/utils/content';
import Notification from '@/components/util/Notification';
import ToggleButton from '@/components/buttons/ToggleButton.vue';
import BaseTable from '@/components/tables/BaseTable.vue';
import { sortWithPrimary } from '@/utils/content';
import { ProjectionType } from '@/utils/enums.js';

export default {
  name: 'ContentLanguages',
  components: { ToggleButton, BaseTable },
  props: {
    content: { type: Object, required: true }
  },
  data() {
    return {
      tableHeaders: [
      {
        name: this.$t('Languages.Language'),
        class: 'uk-width-medium'
      }, {
        name: this.$t('Generic.Labels.Title'), 
        class: 'uk-width-medium'
      },
       {
        name: this.$t('Languages.Description'), 
        class: 'uk-width-2xlarge uk-preserve-width'
      },
      {
        name: this.$t('Pages.Content.Primary'), 
        class: 'uk-float-right'
      },
      {
        name: this.$t('Actions.Actions'), 
        class: 'uk-width-small uk-text-center'
      }],
      form: {
        language: '',
        languageError: '',
        title: '',
        headline: '',
        attribution: '',
        description: '',
        titleError: '',
        category: '',
        projection: '',
        tags: []
      },
      tag: '',
      isLoading: false,
      isFetching: false,
      editLanguage: null,
      deletingList: [],
      SupportedLanguages,
      EntertainmentCategoryList,
      errors: {
        tag: ''
      },
      sortedLanguages: [],
      ProjectionType
    }
  },
  computed: {
    isDisabled() {
      return !this.form.title || this.form.title.trim().length<3 || ( this.form.title && this.form.title.length<3 ) || !this.form.language;
    },
    availableLanguages () {
      return SupportedLanguages.filter(cl => !this.content?.languages?.find(l => cl == l.language));
    }
  },
  methods: {
    addTag () {
      this.tag = this.tag.replace(' ', '')
      if (this.tag.length < 2) return
      if (!this.form.tags.includes(this.tag))
        this.form.tags.push(this.tag)
      this.tag = ''
    },
    deleteTag (tag) {
      var index = this.form.tags.indexOf(tag);
      this.form.tags.splice(index,1);
    },
    validateTitle( title ) {
      if (this.form.titleError) {
        if (title?.length >= 3) {
          this.form.titleError = false
        }
      } else if (title) {
        this.form.titleError = title.length<3 || !title.trim() ? this.$t('Generic.Errors.Min3Chars'): '';
      }
    },
    langTitle( lang ) {
      return this.$t( `Languages.isoLanguageLabels.${lang.language}` )
    },
    resetForm () {
      this.form.language = '';
      this.form.languageError = '';
      this.form.title = '';
      this.form.description = '';
      this.form.headline = '';
      this.form.attribution = '';
      this.form.titleError = '';
      this.form.tags = [];
      this.editLanguage = null;
      this.tag = '';
    },
    clickEdit ( lang ) {
      this.editLanguage = lang;
      window.UIkit.modal('#content-language-modal').show();
      this.form.title = lang.title;
      this.form.headline = lang.headline;
      this.form.attribution = lang.attribution;
      this.form.description = lang.description;
      this.form.language = lang.language;
      this.form.tags= [ ...lang.tags ];
      this.form.category = this.content.category;
      this.form.projection = this.content.projection;
    },
    validateForm () {
      let isValid = true
      if (!this.form.title) {
        isValid = false
        this.form.titleError = this.$t('Generic.Errors.Min3Chars')
      } else {
        this.validateTitle(this.form.title)
        if (this.form.titleError) {
          isValid = false
        }
      }
      if (!this.form.language) {
        isValid = false
        this.form.languageError = this.$t('Generic.Errors.MandatoryField')
      }
      return isValid
    },
    async onSubmit () {
      if (!this.validateForm()) return
      this.isLoading = true;
      const newLang = await this.$store.dispatch('setContentLanguage', {
        content: this.content.id,
        variables: {
          title: this.form.title,
          description: this.form.description,
          language: this.form.language,
          headline: this.form.headline,
          attribution: this.form.attribution,
          ...( this.editLanguage ? { tags: this.form.tags } : {})
        }
      });
      if ( this.editLanguage && this.content.category !== this.form.category ) {
        await this.$store.dispatch('updateContent', { input: { content: this.content.id, category: this.form.category }});
      }
      if ( this.editLanguage && this.content.projection !== this.form.projection ) {
        await this.$store.dispatch('updateContent', { input: { content: this.content.id, projection: this.form.projection }});
      }
      this.isLoading = false;
      window.UIkit.modal('#content-language-modal').hide();
      if ( newLang.data === null ) {
        Notification.showNotification(this.$t("Pages.Content.ContentUpdateError"), newLang?.errors[0]?.message, 'error');
      }
      else if ( newLang && !this.editLanguage )
        Notification.showNotification(this.$t( 'Generic.Messages.Successful'), this.$t( 'Languages.Notification.NewLanguage'));
      else
        Notification.showNotification(this.$t( 'Generic.Messages.Successful'), this.$t( 'Languages.Notification.UpdateLanguage'));
      this.resetForm();
    },
    async setPrimary( lang, isPrimary ) {
      this.isFetching = true;
      const content = await this.$store.dispatch('setContentLanguage', {
        content: this.content.id,
        variables: {
          setToPrimary: isPrimary,
          title: lang.title,
          language: lang.language,
          headline: lang.headline || '',
          description: lang.description || '',
          attribution: lang.attribution || ''
        }
      });
      this.isFetching = false;
      if( content )
        Notification.showNotification(this.$t( 'Generic.Messages.Successful'), this.$t( 'Languages.Notification.UpdateLanguage'));
    },
    async deleteLanguage( lang ) {
      const UIkit = window.UIkit;
      if( lang.isPrimary ) {
        window.UIkit.modal('#info-language-modal').show();
      }
      else {
        UIkit.modal.confirm(this.$t( 'DeleteModal.ConfirmDeleteContentArt', { name: this.langTitle( lang ) }), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }}).then( async () => {
          this.deletingList.push( lang );
          const response = await this.$store.dispatch( 'deleteContentLanguage', { content: this.content.id, language: lang.language } );
          if ( response ) {
            this.deleteLangRef( lang );
            Notification.showNotification(this.$t( 'DeleteModal.Removed'), this.$t( 'DeleteModal.HasBeenRemoved', { name: this.langTitle( lang ) }));
          } else {
            Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), response?.errors[0]?.message, 'error');
          }
        },() => {
      })
      }
    },
    deleteLangRef ( lang ) {
      this.deletingList.forEach( l => {
        if( l == lang )
          this.deletingList.splice(l,1);
      });
    }
  },
  mounted() {
    this.sortedLanguages = sortWithPrimary( this.content?.languages );
  },
  watch: {
    async content () {
      this.sortedLanguages = sortWithPrimary( this.content?.languages );
    }
  }
}
</script>

<style lang="scss" scoped>
.trash-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.add-lang-btn {
  padding: 0 20px;
}
.disable {
  pointer-events: none;
}
.close:hover {
  cursor: pointer;
  text-decoration: none;
  color: #000;
}
.close {
  vertical-align: middle;
  display: flex;
  color: #a8a8a8;
  cursor: pointer;
}
</style>
