<template>
<div id="content-language">
      <div class="uk-text-left">
        <div class="uk-flex-inline">
            <h3 class="uk-text-left uk-margin-remove"> {{ $t('Pages.Content.VisibilityAndAvailability') }} </h3>
            <span class="uk-text-danger uk-margin-auto-vertical uk-margin-small-left" v-if="formChanged">{{$t('Generic.Labels.Unsaved')}} </span>
        </div>
      <div class="uk-grid">
        <div class="uk-margin-small-top  uk-width-auto uk-margin-medium-right">
          <p class="uk-margin-small-bottom uk-margin-small-top uk-text-meta" style=""> {{ $t('Pages.Content.Availability.Visibility')}} </p>
          <div class="uk-button-group" id="content-visibility">
            <div class="">
              <button :class="{ 'selected': contentAccessType === 'PRIVATE' }" class="uk-button uk-button-secondary access uk-width-small" :style="{cursor: disabledAccessValues.includes('PRIVATE') ? 'not-allowed' : 'pointer'}" :disabled="disabledAccessValues.includes('PRIVATE')" @click.prevent="contentVisibility('PRIVATE')">{{ $t( 'Pages.Content.Availability.Private' ) }}</button>
              <button :class="{ 'selected': contentAccessType === 'TESTING'}" class="uk-button uk-button-secondary access uk-width-small" :style="{cursor: disabledAccessValues.includes('TESTING') ? 'not-allowed' : 'pointer'}" :disabled="disabledAccessValues.includes('TESTING')" @click.prevent="contentVisibility('TESTING')">{{ $t( 'Pages.Content.Availability.Testing' ) }}</button>
              <button :class="{ 'selected': contentAccessType === 'PUBLIC'}" class="uk-button uk-button-secondary access uk-width-small" :style="{cursor: disabledAccessValues.includes('PUBLIC') ? 'not-allowed' : 'pointer'}" @click.prevent="contentVisibility('PUBLIC')" :disabled="disabledAccessValues.includes('PUBLIC')">{{ $t( 'Pages.Content.Availability.Public' ) }}</button>
            </div>
          </div>
        </div>

        <div class="uk-margin-small-top uk-flex-inline uk-width-auto">
          <div v-if="content.type === 'VOD' || content.type === 'INTERACTIVE' || content.type === 'AR'" class="uk-margin-large-right uk-margin-small-top">
            <p class="uk-text-meta"> {{ $t('Pages.Content.Availability.AllowShare')}}</p>
            <div class="uk-flex-inline">
                <ToggleButton :value="form.internalAllowSharing" @toggle="form.internalAllowSharing = $event" />
            </div>
          </div>
          <div v-if="content.type === 'VOD' || content.type === 'INTERACTIVE' || content.type === 'AR'" class="uk-margin-large-right uk-margin-small-top">
            <p class="uk-text-meta"> {{ $t('Pages.Content.Availability.AllowDownload')}}</p>
            <div class="uk-flex-inline">
                <ToggleButton :value="form.internalAllowDownloads" @toggle="form.internalAllowDownloads = $event"/>
            </div>
          </div>
          <div v-if="content.type !== 'AD'" class="uk-margin-small-top">
            <p class="uk-text-meta"> {{ $t('Pages.Content.Availability.AvailabilityWindow')}}</p>
            <div class="uk-flex-inline">
                <ToggleButton @toggle="toggleWindow( $event )" :value="form.availabilityTypeWindow" />
            </div>
          </div>
        </div>

      </div>
    </div>
    <div  v-if="content.type !== 'AD'" class="uk-width-1-1 uk-text-left uk-margin-top">
      <div class="uk-flex">
        <div v-if="!form.availabilityTypeWindow" class="uk-width-auto uk-margin-large-right" >
          <InputDatePicker
              id="startDate"
              style="width:420px"
              class="uk-margin-medium-right"
              :label="$t('Pages.Content.Availability.AvailabilityDate')"
              v-model="form.internalAvailability.start"
              enable-time
              handle-utc
              :min-date="minDate"
              :info-text="$t('Pages.Content.Availability.MakeContentPublic')"
              info
              :placeholder="$t('Pages.Content.Availability.SelectDate')"
              required
          />
        </div>
      <div v-if="!form.availabilityTypeWindow" class="uk-margin-left" style="width:420px">
        <InputDatePicker
              id="endDate"
              :label="$t('Pages.Content.Availability.ExpirationDate')"
              v-model="form.internalAvailability.end"
              enable-time
              no-button-now
              :min-date="endDate"
              :info-text="$t('Pages.Content.Availability.MakeContentPrivate')"
              info
              handle-utc
              :placeholder="$t('Pages.Content.Availability.SelectDate')"
              required
          />
      </div>
    </div>
    <div v-if="form.availabilityTypeWindow" class="uk-flex" >
      <InputDatePicker
            style="width:400px"
            class="uk-margin-medium-right"
            id="startAndEndDate"
            v-model="form.internalAvailability"
            formatted="MMM, DD, YYYY"
            handle-utc
            :min-date="minDate"
            range
            :label="$t('Pages.Content.Availability.SelectDateRange')"
            :start="form.internalAvailability.start"
            :end="form.internalAvailability.end"
            @input="selectedDateRange"
      />
      <InputTimePicker
            style="width:160px"
            id="start"
            class="uk-margin-left uk-margin-medium-right"
            v-model="form.startTime"
            :label="$t('Pages.Content.Availability.SelectStartTime')"
            value="00:00"
            handle-utc
            @input="form.startTime=$event"
      />
      <InputTimePicker
            style="width:160px"
            id="end"
            class=" uk-margin-left"
            v-model="form.endTime"
            :label="$t('Pages.Content.Availability.SelectEndTime')"
            value="00:00"
            handle-utc
            :min-date="minTime"
            @input="form.endTime=$event"
      />
      </div>
      <div class="uk-text-danger uk-text-left" v-if="validationError.length">{{ validationError }}</div>
    </div>
      <hr class="uk-margin-top " />
      <div class="uk-text-center uk-margin ">
        <button v-if="!saving" class="uk-button uk-button-primary uk-width-small" type="button" @click="updateContent">{{ $t( 'Actions.Save' ) }}</button>
        <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
      </div>    
  </div>
</template>

<script>
import moment from 'moment';
import ToggleButton from '@/components/buttons/ToggleButton.vue';
import InputDatePicker, { dateFormat } from '@/components/InputDatePicker';
import InputTimePicker from '@/components/InputTimePicker.vue';
import { disabledAccessTypeBasedOnAvailability, disabledAccessTypeBasedOnAvailabilityWindow } from '../../../utils/content';
import Notification from '@/components/util/Notification';

export default {
  name: 'ContentAvailability',
  components: { ToggleButton, InputDatePicker, InputTimePicker },
  props: {
    content: { type: Object, required: true }
  },
  data() {
    const { allowDownloads, allowSharing, availabilityWindow, access } = this.content;
    return {
      formChanged: false,
      detectChange: false,
      formSignature: '',
      form: {
        internalAllowDownloads: allowDownloads,
        internalAllowSharing: allowSharing,
        internalAccessType: access,
        internalAvailability: {
                start: availabilityWindow?.start || availabilityWindow?.startDate
                    ? availabilityWindow?.__typename === 'AvailabilityWindow' ? moment( availabilityWindow?.startDate ).format( 'YYYY-MM-DD' ) : availabilityWindow?.start
                    : null,
                end: availabilityWindow?.end || availabilityWindow?.endDate
                    ? availabilityWindow?.__typename === 'AvailabilityWindow' ? moment( availabilityWindow?.endDate ).format( 'YYYY-MM-DD' ) : availabilityWindow?.end
                    : null,
                shortcut: null
        },
        startTime: availabilityWindow?.startTime ? moment( availabilityWindow?.startTime ).format( 'HH:mm:ssZ' ) : null,
        endTime: availabilityWindow?.endTime ? moment( availabilityWindow?.endTime ).format( 'HH:mm:ssZ' ) : null,
        availabilityTypeWindow: availabilityWindow?.__typename === 'AvailabilityWindow',
      },
      saving: false,
      validationError: ''
    }
  }, 
  computed: {
    minDate () {
      return moment().format( dateFormat );
    },
    minTime () {
      return moment( `1970-01-01T${this.form.startTime}` ).format( dateFormat );
    },
    endDate () {
      const { start } = this.form.internalAvailability;
      if ( start ) {
        const date = start && new Date( start );
        return moment( date?.setMinutes( date.getMinutes() + 1 ) ).format( dateFormat );
      }
      return this.minDate;
    },
    contentAccessType () {
      const { start, end } = this.form.internalAvailability;
      const { startTime, endTime } = this.form;
      const currentTime = moment().utc().format( 'YYYY-MM-DDTHH:mm:ss[Z]' );
      const currentDate = moment().utc().format( 'YYYY-MM-DD' );
      if ( !this.form.availabilityTypeWindow) {
        if ( ( end === null && start<=currentTime ) || (  start <= currentTime && end >= currentTime  ) || ( start === null && end>=currentTime )) {
          return 'PUBLIC';
        }
      }
      if ( this.form.availabilityTypeWindow ) {
        if ( start <= currentDate && end >= currentDate || start <= currentDate && end === null ) {
          if ( startTime < moment().format( 'HH:mm:ssZ' ) && endTime > moment().format( 'HH:mm:ssZ' ) ) {
            return 'PUBLIC';
          }
        }
      }
      return this.form.internalAccessType; 
    },
    disabledAccessValues () {
      return this.form.availabilityTypeWindow
                ? disabledAccessTypeBasedOnAvailabilityWindow( this.form.internalAvailability, this.form.startTime, this.form.endTime )
                : disabledAccessTypeBasedOnAvailability( this.form.internalAvailability, this.form.internalAccessType );
    }
  },
  methods: {
    getSignature () {
      return JSON.stringify(this.form);
    },
    toggleWindow( value ) {
      this.form.availabilityTypeWindow = value;
    },
    selectedDateRange ( range ) {
      if ( Object.keys( range ).length === 0 ) {
        this.form.internalAvailability.start = null;
        this.form.internalAvailability.end = null;
      }
      if ( range ) {
        this.form.internalAvailability.start = range.start;
        this.form.internalAvailability.end = range.end;
      }
    },
    contentVisibility( type ) {
      this.form.internalAccessType = type;
    },
    async updateContent () {
      const { internalAvailability, availabilityTypeWindow, startTime, endTime } = this.form;
              const { start, end } = internalAvailability;
                if ( !start && !end && ( startTime || endTime ) && availabilityTypeWindow ) {
                  this.validationError = this.$t('Pages.Content.Availability.DateRangeRequired');
                } else if ( ( start || end ) && startTime && !endTime && availabilityTypeWindow ) {
                  this.validationError = this.$t('Pages.Content.Availability.EndTimeRequired');
                } else if ( ( start || end ) && !startTime && endTime && availabilityTypeWindow) {
                  this.validationError = this.$t('Pages.Content.Availability.StartTimeRequired');
                } else if ( ( start || end ) && availabilityTypeWindow &&!startTime && !endTime ) {
                  this.validationError = this.$t('Pages.Content.Availability.TimeRequired');
                } else if ( ( startTime || endTime ) && startTime === endTime && availabilityTypeWindow) {
                  this.validationError = this.$t('Pages.Content.Availability.StartEndTimeDifferent');
                } else if ( ( start && end && moment(start).isSame(moment(end))) ) {
                  this.validationError = this.$t('Pages.Content.Availability.StartEndDateDifferent');
                } else if ( start && end && moment(start).isAfter(moment(end)) && !availabilityTypeWindow ) {
                  this.validationError = this.$t('Pages.Content.Availability.StartDateIsAfterEnd');
                } else if ( startTime > endTime  && availabilityTypeWindow) {
                  this.validationError = this.$t('Pages.Content.Availability.StartTimeIsAfterEnd');
                } else {
                  this.validationError = '';
                this.saving = true;
                const getMoment = ( date, defaultMoment = '' ) => (
                ( date && Object.keys( date ).length ) ? moment( date ) : defaultMoment );

            const availabilityState = {
                start: getMoment( this.form.internalAvailability?.start ),
                end: getMoment( this.form.internalAvailability?.end ),
                startTime: ( this.form.availabilityTypeWindow && this.form.startTime ) || '',
                endTime: ( this.form.availabilityTypeWindow && this.form.endTime ) || ''
            };
            if ( availabilityTypeWindow ) {
              if ( this.form.internalAvailability?.start === this.form.internalAvailability?.end ) {
                  availabilityState.end = '';
              }
            }
        const response = await this.$store.dispatch('updateContent', {
              input: {
                      content: this.content.id,
                      access: this.form.internalAccessType,
                      availability: this.content.type !== 'AD' ? availabilityState : null,
                      allowDownloads: this.form.internalAllowDownloads,
                      allowSharing: this.form.internalAllowSharing
                  }
          });
            this.saving = false;
            this.validationError = '';
            
          if ( response?.updateContentInfo?.content ) {
            this.formSignature = this.getSignature();
            this.formChanged = false;
            this.$emit( 'formChanged', this.formChanged );
            Notification.showNotification(this.$t( 'Generic.Messages.Successful'),this.$t("Pages.Content.UpdateContent"));
          } else
            Notification.showNotification(this.$t("Pages.Content.ContentUpdateError"), response, 'error');
        }
    }
  },
  watch: {
    content: {
      handler ( newVal ) {
        if ( newVal ) {
          const { availabilityWindow, access } = newVal;
          this.form.internalAccessType = access;
          this.form.internalAvailability = {
                start: availabilityWindow?.start || availabilityWindow?.startDate
                    ? availabilityWindow?.__typename === 'AvailabilityWindow' ? moment( this.content.availabilityWindow?.startDate ).format( 'YYYY-MM-DD' ) : availabilityWindow?.start
                    : null,
                end: availabilityWindow?.end || availabilityWindow?.endDate
                    ? availabilityWindow?.__typename === 'AvailabilityWindow' ? moment( this.content.availabilityWindow?.endDate ).format( 'YYYY-MM-DD' ) : availabilityWindow?.end
                    : null,
                shortcut: null
            }
        }
      },
      deep: true,
      immediate: true
      },
    form: {
      handler () {
        const { internalAvailability, availabilityTypeWindow, startTime, endTime } = this.form;
              const { start, end } = internalAvailability;
              if ( availabilityTypeWindow ) {
                if ( start === end ) {
                    internalAvailability.end = '';
                }
              }
        if (this.detectChange && this.formSignature) {
          this.$nextTick(() => {
            const newSignature = this.getSignature()
            if (newSignature !== this.formSignature) {
              this.formChanged = true
              this.$emit( 'formChanged', this.formChanged );
              if( this.validationError ) {
                if ( !start && !end && ( startTime || endTime ) && availabilityTypeWindow ) {
                  this.validationError = this.$t('Pages.Content.Availability.DateRangeRequired');
                } else if ( ( start || end ) && startTime && !endTime && availabilityTypeWindow ) {
                  this.validationError = this.$t('Pages.Content.Availability.EndTimeRequired');
                } else if ( ( start || end ) && !startTime && endTime && availabilityTypeWindow) {
                  this.validationError = this.$t('Pages.Content.Availability.StartTimeRequired');
                } else if ( ( start || end ) && availabilityTypeWindow &&!startTime && !endTime ) {
                  this.validationError = this.$t('Pages.Content.Availability.TimeRequired');
                } else if ( ( startTime || endTime ) && startTime === endTime && availabilityTypeWindow) {
                  this.validationError = this.$t('Pages.Content.Availability.StartEndTimeDifferent');
                } else if ( ( start && end && availabilityTypeWindow && moment(start).isSame(moment(end))) ) {
                  this.validationError = this.$t('Pages.Content.Availability.StartEndDateDifferent');
                } else if ( moment(start).isAfter(moment(end)) && !availabilityTypeWindow ) {
                  this.validationError = this.$t('Pages.Content.Availability.StartDateIsAfterEnd');
                } else if ( startTime > endTime  && availabilityTypeWindow) {
                  this.validationError = this.$t('Pages.Content.Availability.StartTimeIsAfterEnd');
                } else {
                  this.validationError = '';
                }
              }

            } else {
              this.formChanged = false
              this.$emit( 'formChanged', this.formChanged )
            }
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    setTimeout(() => {
      this.formSignature = this.getSignature()
      this.formChanged = false
      this.detectChange = true
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>
.selected {
  background-color: var(--app-primary-color);
}

.access {
  padding: 0 10px;
}
</style>
