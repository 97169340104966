<template>
    <div id="content-information" class="uk-width-1-1">
        <div v-if="isContentInformationAvailable" class="uk-clearfix uk-width-1-1">
            <h2 class="uk-text-left uk-width-1-2 uk-margin-remove"> {{ $t( 'Pages.Content.PrimaryVideoInformation') }} </h2>
            <button style="padding-left:20px; padding-right:20px" class="uk-button uk-button-primary uk-margin-small-top uk-margin-small-bottom uk-align-left" type="button"  @click="copy"> {{ copied ? 'Copied' : 'Copy' }} URL {{ $t('Pages.MediaLibrary.Labels.ToClipboard') }} </button>
        </div>
        <form v-if="isContentInformationAvailable" class="uk-form-horizontal uk-text-left uk-margin-small uk-width-1-2">
            <div class="uk-clearfix">
                <label class="uk-form-label uk-margin-small">{{ $t( 'Pages.Content.Information.Labels.Type' ) }}:</label>
                <span class="uk-form-controls uk-margin-medium-left capital"> {{getMediaType}} </span>
            </div>
            <div class="uk-clearfix">
                <label class="uk-form-label uk-margin-small">{{ $t( 'Pages.Content.Information.Labels.Size' ) }}</label>
                <span class="uk-form-controls uk-margin-medium-left"> {{ getMediaSizeBytes( content.ref.primaryFormat.media ) | appropriateBytes }} </span>
            </div>
            <div class="uk-clearfix">
                <label class="uk-form-label uk-margin-small">{{ $t( 'Pages.Content.Information.Labels.Resolution' ) }}:</label>
                <span class="uk-form-controls uk-margin-medium-left"> {{getMediaResolutionLabel(content.ref.primaryFormat.media)}} </span>
            </div>
            <div class="uk-clearfix">
                <label class="uk-form-label uk-margin-small">{{ $t( 'Pages.Content.Information.Labels.Duration' ) }}:</label>
                <span class="uk-form-controls uk-margin-medium-left"> {{ getDuration }} </span>
            </div>
            <div class="uk-clearfix">
                <label class="uk-form-label uk-margin-small">{{ $t( 'Pages.Content.Information.Labels.Bitrate' ) }}:</label>
                <span class="uk-form-controls uk-margin-medium-left"> {{ getBitrate }} </span>
            </div>
            <div class="uk-clearfix">
                <label class="uk-form-label uk-margin-small">{{ $t( 'Pages.Content.Information.Labels.VideoCodec' ) }}:</label>
                <span class="uk-form-controls uk-margin-medium-left"> {{ $t( `enums.ProjectionType.${getProjection}` ) || $t('Generic.Messages.NotAvailable') }} </span>
            </div >
            <div class="uk-clearfix">
                <label class="uk-form-label uk-margin-small">{{ $t( 'Pages.Content.Information.Labels.AudioCodec' ) }}:</label>
                <span class="uk-form-controls uk-margin-medium-left capital"> {{getAudioCodec}} </span>
            </div>
            <div class="uk-clearfix">
                <label class="uk-form-label uk-margin-small">{{ $t( 'Pages.Content.Information.Labels.Framerate' ) }}:</label>
                <span class="uk-form-controls uk-margin-medium-left"> {{getFrameRate}} </span>
            </div>
        </form>
        <div v-else>
            <span class="uk-text-meta"> {{$t('Pages.Content.NoVideoInformation')}} </span>
        </div>
        <hr v-if="isHls" />
        <form v-if="isHls && hlsPlaylists.length > 0" class="uk-form-horizontal uk-width-1-2 uk-text-left ">
            <h2> {{ $t( 'Pages.Content.HLSPlaylists') }} </h2>
            <hr/>
            <div v-for="(playlist,i) in hlsPlaylists" :key="i" class="uk-margin-small">
                <h4> {{ $t( 'Pages.Content.Information.Labels.Playlist') }} {{ i + 1 }} </h4>
                <hr />
                <div class="uk-clearfix">
                    <label class="uk-form-label uk-margin-small">{{ $t( 'Pages.Content.Information.Labels.PlaylistCodec' ) }}:</label>
                    <span class="uk-margin-medium-left capital"> {{playlist.attributes.CODECS}} </span>
                </div>
                <div class="uk-clearfix">
                    <label class="uk-form-label uk-margin-small">{{ $t( 'Pages.Content.Information.Labels.Resolution' ) }}:</label>
                    <span class="uk-margin-medium-left"> {{getMediaResolutionLabel(playlist.attributes)}} </span>
                </div>
                <div class="uk-clearfix">
                    <label class="uk-form-label uk-margin-small">{{ $t( 'Pages.Content.Information.Labels.Bandwidth' ) }}:</label>
                    <span class="uk-margin-medium-left"> {{playlist.attributes.BANDWIDTH | appropriateBytes}}  </span>
                </div>
                <div class="uk-clearfix">
                    <label class="uk-form-label uk-margin-small">{{ $t( 'Pages.Content.Information.Labels.AvgBandwidth' ) }}:</label>
                    <span v-if="getAvgBandwidth(playlist)" class="uk-margin-medium-left"> {{ playlist.attributes['AVERAGE-BANDWIDTH'] | appropriateBytes }} </span>
                    <span v-else class="uk-margin-medium-left"> {{$t('Generic.Messages.NotAvailable')}} </span>
                </div>

                <div class="uk-clearfix">
                    <label class="uk-form-label uk-margin-small">{{ $t( 'Pages.Content.Information.Labels.AvgFrameRate' ) }}:</label>
                    <span class="uk-margin-medium-left"> {{ playlist.attributes['FRAME-RATE'] || $t('Generic.Messages.NotAvailable') }} </span>
                </div>
                <hr/>
            </div>
        </form>
    </div>
</template>

<script>
import { getMediaSizeBytes } from '@/utils/mediaItem';
import clipboardCopy from 'clipboard-copy';
import m3u8Manager from '@platform/m3u8-parser-http';

export default {
    name: 'ContentInformation',
    props: {
        content: { type: Object, required: true }
    },
    mounted () {
        if(this.isHls)
            this.hlsParser();
    },
    data () {
        return {
            playlists: [],
            copied: false
        }
    },
    computed: {
         isContentInformationAvailable(){
            return this.content.ref.primaryFormat?.media || undefined;
        },
        isHls () {
            return this.content.ref?.primaryFormat?.media?.mediaType === 'application/x-mpegURL';
        },
        getFrameRate () {
            const videoStream = this.content.ref.primaryFormat.media?.rawMetadata?.streams?.find( stream => stream.codec_type === 'video' );
            return videoStream?.avg_frame_rate?.split("/")[0] + " FPS"  || this.$t('Generic.Messages.NotAvailable');
        },
        getAudioCodec () {
            const audioStream = this.content.ref.primaryFormat.media?.rawMetadata?.streams?.find( stream => stream.codec_type === 'audio' );
            return audioStream?.codec_name || this.$t('Generic.Messages.NotAvailable');
        },
        hlsPlaylists () {
            return this.playlists;
        },
        getDuration () {
            return this.secondsToHms(this.content.ref.primaryFormat?.media?.rawMetadata?.format?.duration) || this.$t('Generic.Messages.NotAvailable');
        },
        getBitrate () {
            return this.bitRateUnitWrapper( this.content.ref.primaryFormat?.media?.rawMetadata?.format?.bit_rate) || this.$t('Generic.Messages.NotAvailable');
        },
        getMediaType () {
            return this.content.ref.primaryFormat?.media?.mediaType || this.$t('Generic.Messages.NotAvailable');
        },
        getProjection () {
            return this.content.ref.primaryFormat?.media?.projection;
        }
    },
    methods: {
       
        getMediaSizeBytes,
        getMediaResolutionLabel ( media ) {
            const { width, height } = media ? (media.resolution || media.RESOLUTION) : {};

            return ( width || height ) ? `${width} × ${height}` : 'Not Available';
        },
        bitRateUnitWrapper(d){
            d = Number(d);
            let unit = 'bps';
            if(d > 1000){
                d = (d/1000).toFixed(2);
                unit = 'Kbps';
            }
            if(d>1000){
                d = (d/1000).toFixed(2);
                unit = 'Mbps';
            }
              if(d>1000){
                d = (d/1000).toFixed(2);
                unit = 'Gbps';
            }

            return `${d} ${unit}`;
            
        },
        secondsToHms(d) {
            d = Number(d);
            var h = Math.floor(d / 3600);
            var m = Math.floor(d % 3600 / 60);
            var s = Math.floor(d % 3600 % 60);

            var hDisplay = h > 0 ? h + (h == 1 ? " Hour, " : "Hours, ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " Minute, " : " Minutes, ") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? " Second" : " Seconds") : "";
            return hDisplay + mDisplay + sDisplay; 
        },
        async hlsParser () {
            const url = this.content.ref.primaryFormat.media.file.url;
            // Instantiate new m3u8Manager class
            const hlsutil = new m3u8Manager(url);

            // Parse and print complete HLS manifest
            const manifest = await hlsutil.parse();
           
            this.playlists =  manifest.playlists;
        },
        async copy () {
            await clipboardCopy( this.content.ref.primaryFormat.media.file.url );
            this.copied = true;
        },
        getAvgBandwidth ( playlist ) {
            if(playlist.attributes['AVERAGE-BANDWIDTH']){
                return playlist.attributes['AVERAGE-BANDWIDTH'];
            }
            return null;
        }
    }
}
</script>

<style lang="scss" scoped>
.capital {
    text-transform: uppercase;
}
</style>