<template>
  <div id="content-formats">
    <div class="uk-left-right uk-margin-bottom">
      <router-link type="button" v-if="mediaBundle" :to="{ name: 'MediaBundle', params: { id: orgId, bundleId: mediaBundle } }" class="uk-link uk-float-left source-media uk-margin-bottom">{{ $t('Pages.Content.SourceMedia') }}</router-link>
    </div>
    <div class="uk-float-right uk-margin-bottom">
      <button class="uk-button uk-button-primary add-format-btn" v-if="uncoupledMedia.length>0" @click="openMediaPicker()">
        <span uk-icon="plus-circle" ></span> {{ $t( 'Pages.Content.Formats.AddFormat' ) }}
      </button>
    </div>
    <div 
      class="uk-grid-small uk-width-expand uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl uk-margin-medium-top"
      uk-scrollspy="target: > div.uk-grid-item-match; cls: uk-animation-scale-up; delay: 50; offset-top: 2000;"
      uk-grid
      >
      <div v-for="format in sortedFormats( content.ref )" :key="createKey( format )" class="uk-grid-item-match">
          <div class="uk-card uk-card-default uk-card-small uk-text-left uk-card-hover uk-border-rounded">
            <div class="card-body-max-height uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
              <div class="uk-text-center">
                <div uk-icon="icon: video-camera; ratio: 3"/>
                <h5 class="uk-card-title uk-margin-remove-top uk-margin-remove-bottom uk-text-truncate title-font" :uk-tooltip="formatDescription( format )" style="font-size:1rem">
                  {{ formatDescription( format )}}
                </h5>
                <p class="uk-margin-remove uk-text-truncate meta-data">{{ $t( `enums.ProjectionType.${formatProjection( format) }` ) }}</p>
                <p class="uk-margin-remove uk-text-truncate meta-data"> {{ mediaType(format) }} </p>
                <p class="uk-margin-remove-top meta-data">{{ (resolutionText(format)) }}</p>
              </div>
            </div>
            <div class="uk-card-footer">
              <div class="uk-text-meta uk-float-right">
                <span v-if="deletingList.includes( format )" uk-spinner="ratio: 0.7" />
                <div v-else-if="!format.isPrimary" uk-icon="trash" id=js-modal-confirm :uk-tooltip="$t('Actions.Delete')" class="uk-link" @click="deleteFormat( format )"></div>
              </div>
              <div class="uk-text-meta uk-float-right uk-margin-small-right">
                <div uk-icon="pencil" class="uk-link" :uk-tooltip="$t('Breadcrumbs.edit')" @click="editFormatDetails( format )" href="#edit-format-modal" uk-toggle></div>
              </div>
              <div class="uk-flex-inline" :class="{'disable': format.isPrimary}">
                <span v-if="format.isPrimary" class="uk-label uk-label-success">{{ $t( 'Pages.Content.Formats.PrimaryFormat' ) }}</span>
                <button v-else class="make-primary" :disabled="isSetting" type="button" @click="setPrimary(format, true)">{{ $t( 'Pages.Content.MakePrimary' ) }}</button>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div container="#content-formats" id="edit-format-modal" uk-modal="bg-close: false; esc-close: false">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <div class="uk-modal-header">
          <h3 class="uk-text-left uk-modal-title"> {{ $t( 'Pages.Content.Formats.EditFormat' ) }} </h3>
        </div>
        <div class="uk-modal-body uk-flex uk-flex-column">
          <div class="uk-form-stacked uk-text-left">
            <button class="uk-modal-close-default" type="button" uk-close @click="resetForm()"></button>
            <div>
              <label class="uk-form-label"> {{ $t('Pages.MediaLibrary.Labels.Description') }} <span class="uk-text-danger">*</span></label>
              <div class="uk-inline uk-width-1-1">
                <textarea class="uk-textarea" 
                      :class="{ 'invalid-border': isDisabled }"
                      rows="2"
                      type="text"
                      v-model.trim="description"
                      autocomplete="off"
                      :placeholder="$t('Pages.Content.Placeholders.Description')" />
                      <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="isDisabled" uk-icon="icon: warning" ></span>
                      <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="isDisabled" >{{ $t( 'Pages.Content.Formats.MinDescription' ) }}</span>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label"> {{ $t('Pages.MediaLibrary.Labels.SourceURL') }}</label>
              <div>
                <p class="uk-text-decoration-none uk-text-meta uk-text-break uk-width-large">{{ formatUrl(editFormat) }} </p>
              </div>
            </div>
            <div class="uk-flex uk-width-1-1 uk-margin">
              <div class="uk-width-1-2">
                <label class="uk-form-label"> {{ $t('Pages.MediaLibrary.Labels.ProjectionType') }} </label>
                 <div class="uk-margin-small-top uk-margin-small-right">
                  <p class="uk-text-meta uk-margin-remove-top">{{ $t( `enums.ProjectionType.${formatProjection( editFormat )}` ) }}</p>
                </div>
              </div>
              <div class="uk-width-1-2">
                <label class="uk-form-label"> {{ $t('Pages.Content.Formats.FormatType') }} </label>       
                <p class="uk-text-meta uk-margin-remove-top">{{ mediaType(editFormat) }} </p>
              </div>
            </div>
            <div class="uk-flex uk-width-1-1 uk-margin-top">
              <div class="uk-width-1-2">
                <label class="uk-form-label"> {{ $t('Pages.MediaLibrary.Labels.Blindspot') }} </label>
                <p class="uk-text-meta uk-margin-remove-top">{{ $t( `enums.BlindspotType.${blindSpot(editFormat)}` ) }}</p>
              </div>
              <div class="uk-width-1-2">
                <label class="uk-form-label"> {{ $t('Pages.MediaLibrary.Labels.Resolution') }} </label>       
                <p class="uk-text-meta uk-margin-remove-top">{{ (resolutionText(editFormat)) }} </p>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-width-1-1 uk-text-right">
           <button class="uk-button uk-button-default uk-modal-close uk-margin-right" @click="resetForm()">{{ $t('Actions.Cancel') }}</button>
          <button v-if="!isLoading" class="uk-button uk-button-primary" @click="onSubmit()">{{ $t('Actions.Save') }}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
    </div>
     <div v-if="showPicker" id="media-picker-formats-modal" container="#content-formats" uk-modal>
      <div class="uk-modal-dialog uk-modal-body uk-width-3-4">
        <ModalMediaPicker media-class-filter="VIDEO" :media-list="uncoupledMedia" @cancel="closeModal()" @select="selectedFormat( $event )" />
      </div>
  </div>
</div>
</template>

<script>
import ModalMediaPicker from '@/components/modals/ModalMediaPicker.vue';
import { ProjectionType, BlindspotType } from '@/utils/enums';
import Notification from '@/components/util/Notification';
import { getContentVODBundleMediaUncoupled, sortWithPrimary } from '@/utils/content';

export default {
  name: 'ContentFormats',
  components: { ModalMediaPicker },
  props: {
    content: { type: Object, required: true },
    orgId: { type: String, required: true }
  },
  data () {
    return {
      description: '',
      editFormat: null,
      BlindspotType,
      ProjectionType,
      deletingList: [],
      isLoading: false,
      showPicker: false,
      uncoupledMedia: [],
      isSetting: false
    }
  },
  computed: {
    isDisabled() {
      return !this.description || this.description.trim().length<3 || ( this.description && this.description.length<3 );
    },
    mediaBundle () {
      return this.content?.ref?.primaryFormat?.media?.bundle?.id;
    }
  },
  methods: {
    sortedFormats ({ formats }) {
      return sortWithPrimary( formats );
    },
    createKey ( format ) {
      const formatDescription = format.formatDescription || '';
      return `${format.id}-${formatDescription.replace( /[^a-z]/ig, '-' )}-${format.isPrimary}`;
    },
    formatDescription ( format ) {
      return format?.formatDescription || format?.media?.title || format?.video?.title || 'Not Available';
    },
    resolutionText ( format ) {
      if ( format ) {
         const { width, height } = format.media?.resolution || format.video?.resolution || {};
            return ( width || height ) ? `${width} × ${height}` : '0 × 0';
      }
      return '0 × 0';
    },
    formatProjection ( format ) {
      return format?.media === null ? format?.video?.projection : format?.media?.projection || '';
    },
    formatUrl ( format ) {
      return format?.media ? format?.media?.file?.url : format?.video?.file?.url || '';
    },
    validateDescription( description ) {
      return description?.length<3 || !description.trim();
    },
    editFormatDetails( format ) {
      this.editFormat = format;
      this.description = this.formatDescription( format );
    },
    resetForm() {
      this.description = this.formatDescription( this.editFormat );
      this.isDisabled = false;
    },
    mediaType( format ) {
      if ( !format || format?.media?.mediaType === null)
        return 'None';
      return format?.media ? format?.media?.mediaType : format?.video?.mediaType ;
    },
    blindSpot( format ) {
      return format?.media ? format?.media?.blindspotType : format?.video?.blindspotType;
    },
    async deleteFormat( format ) {
        const UIkit = window.UIkit;
        UIkit.modal.confirm(this.$t( 'DeleteModal.ConfirmDeleteContentArt', { name: this.formatDescription(format) }), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }}).then( async () => {
          this.deletingList.push( format );
          const response = await this.$store.dispatch( 'deleteVodFormat', { format: format.id, content: this.content.id });
            this.deleteFormatRef( format );
          if ( response?.deleteVodFormat ) {
            Notification.showNotification(this.$t( 'DeleteModal.Removed'), this.$t( 'DeleteModal.HasBeenRemoved', { name: this.formatDescription( format ) }));
          } else {
            Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), response?.errors[0]?.message, 'error');
          }
        },()=>{});
        setTimeout( () => {
          const modalEl = document.querySelector(".uk-modal:not([id])");
          modalEl.style.display = "block";
          modalEl.classList.add("uk-open");
        }, 100 );
    },
    deleteFormatRef ( format ) {
      this.deletingList.forEach( i=> {
        if( i.id == format.id )
          this.deletingList.splice(i,1);
      });
    },
    async onSubmit ( ) {
      if (this.isDisabled) return true
      this.isLoading = true;
      const format = await this.$store.dispatch('updateVodFormat', {
        content: this.content.id, input: { vodFormat: this.editFormat.id, description: this.description }
      });
      this.isLoading = false;
      window.UIkit.modal('#edit-format-modal').hide();
      this.resetForm();
      if( format?.updateVodFormat )
        Notification.showNotification(this.$t( 'Generic.Messages.Successful' ), this.$t( 'Pages.Content.Formats.UpdateFormat'));
      else
        Notification.showNotification(this.$t("Pages.Content.FormatUpdateError"), format, 'error');
    },
    async setPrimary( format, isPrimary ) {
      this.isSetting = true;
      const contentFormat = await this.$store.dispatch('updateVodFormat', {
          content: this.content.id,
          input: { setToPrimary: isPrimary, vodFormat: format.id }
      });
      this.isSetting = false;

      if( contentFormat?.updateVodFormat )
        Notification.showNotification(this.$t( 'Generic.Messages.Successful' ), this.$t( 'Pages.Content.Formats.SetPrimary'));
      else
        Notification.showNotification(this.$t("Pages.Content.Formats.FormatUpdateError"), contentFormat, 'error');
    },
    openMediaPicker () {
      this.showPicker = true;
      setTimeout( () => {
        window.UIkit.modal('#media-picker-formats-modal').show();
      }, 100 );
    },
    closeModal () {
      window.UIkit.modal('#media-picker-formats-modal').hide();
      setTimeout( function() {
        this.showPicker = false;
      }, 100 );
    },
    async getContentFormatMediaUncoupled ( contentVOD ) {
      if ( contentVOD ) {
        const primaryFormatMedia = contentVOD?.ref?.primaryFormat?.media;
        if ( primaryFormatMedia?.bundle?.id ) {
          const bundle = await this.$store.dispatch('fetchMediaBundle', { bundleId: primaryFormatMedia?.bundle?.id  } );
          return getContentVODBundleMediaUncoupled( contentVOD, bundle );
        }
      }
      return [];
    },
    async selectedFormat ( media ) {
      if ( media  ) {
        const newFormat= await this.$store.dispatch( 'createVodFormat', { content: this.content.id, media });
        this.closeModal();
        if( newFormat.data?.createVodFormat )
          Notification.showNotification( this.$t( 'Generic.Messages.Successful' ), this.$t( 'Pages.Content.Formats.CreateFormat' ) );
      }
    }
  },
  async mounted () {
    this.uncoupledMedia = await this.getContentFormatMediaUncoupled( this.content );
  },
  watch: {
    async content () {
      this.uncoupledMedia = await this.getContentFormatMediaUncoupled( this.content );
    }
  },
}
</script>

<style scoped>
.disable {
  pointer-events: none;
}
.add-format-btn {
  padding: 0 20px;
}
.meta-data {
  font-size: 13px;
}
.source-media {
  color: var(--app-primary-color);
}
.make-primary {
  background-color: var(--app-primary-color);
  border: none;
  color: white;
  border-radius: 16px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

</style>