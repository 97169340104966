<template>
     <div class="input-time-picker">
        <p class="uk-text-left uk-margin-small-top uk-margin-remove-bottom uk-text-meta" v-if="label">
            {{ label }}
        </p>
        <div class="uk-margin-small">
            <FlatPickr
                :id="id"
                v-model="internalValue"
                no-label
                type="time"
                :label="label"
                only-time
                format="HH:mm"
                formatted="HH:mm"
                output-format="HH:mm:ss"
                color="#9040ff"
                minute-interval="5"
                :min-date="minDate"
                @input="change"
                @validate="emit"
                :dark="theme === 'dark'"
            />
        </div>
    </div>
</template>

<script>
import FlatPickr from '@platform/vue-ctk-date-time-picker';
import '@platform/vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
    name: 'InputTimePicker',
    components: { FlatPickr },
    props: {
        id: { type: String, default: null },
        value: { type: [ Date, String ], default: null },
        label: { type: String, default: null },
        minDate: { type: String, default: null },
        handleUtc: { type: Boolean }
    },
    data () {
      return {
        internalValue: this.value
      };
    },
    computed: {
        ...mapState({
          theme: state => state.venom.theme
        })
    },
    methods: {
      change ( time ) {
        this.internalValue = time;
        this.emit();
      },
      emit () {
        if ( this.handleUtc && this.internalValue ) {
          const offset = new Date().getTimezoneOffset();
          const handledValue = moment( `${moment().format( 'YYYY-MM-DD' )}T${this.internalValue}` ).utcOffset( -( offset ) ).format( 'HH:mm:ssZ' );
          this.$emit( 'input', handledValue );
        } else {
          this.$emit( 'input', this.internalValue );
        }
      }
    },
    watch: {
        theme ( newValue ) {
          this.theme = newValue;
        }
    }
};
</script>
