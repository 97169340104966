<template>
  <div id="content-details">
     <div class="uk-flex uk-text-left">
      <h2 class="uk-text-left uk-text-truncate"><span @click="navigateBack" style="text-decoration: none;"><span uk-icon="icon: chevron-left; ratio: 2"></span>
        <span v-if="contentFetching">{{ $t('Actions.Loading')}}</span>
        <span v-else>{{ getContentTitle( contentItem ) }}</span>
        </span>
      </h2>
    </div>
    <ul uk-tab>
      <li><a v-if="isTypeArItem" href="">{{ $t('Pages.Content.Ar.MediaList') }}</a><a v-else href="">{{ $t('Pages.Content.VideoAndArt') }}</a></li>
      <li v-if="isMutable"><a href="#languages">{{ $t('Pages.Content.Languages') }}</a></li>
      <li v-if="isMutable"><a href="#availability">{{ $t('Pages.Content.VisibilityAndAvailability') }} <span class="uk-text-danger" v-if="contentAvailabilityUnsaved">*</span> </a></li>
      <li v-if="isTypeVOD && isMutable"><a href="">{{ $t('Pages.Content.VideoFormats') }}</a></li>
      <li v-if="isTypeVOD && isMutable"><a href="">{{ $t('Generic.Labels.Subtitles') }}</a></li>
      <li v-if="isTypeVOD && isMutable"><a href="">{{ $t('Pages.Content.AppAssets') }}</a></li>
      <li v-if="isTypeStream && isMutable"><a href="#cameras">{{ $t('Pages.Content.Cameras') }}</a></li>
      <li v-if="!isTypeArItem && !isTypeAdItem && isMutable && (featureSupport && featureSupport.ads)"><a href="">{{ $t('Pages.Content.LinkVideoAds') }} <span class="uk-text-danger" v-if="contentAdsUnsaved">*</span> </a></li>
      <li v-if="isMutable"><a href="">{{ $t('Pages.Content.Permissions') }}<span class="uk-text-danger" v-if="contentPermissionsUnsaved">*</span></a></li>
      <li v-if="isTypeVOD"> <a href=""> {{ $t('Pages.Content.VideoInformation') }} </a> </li>
    </ul>
    <ul class="uk-switcher uk-margin" v-if="contentItem.id === contentId">
      <li>
        <div v-if="isTypeArItem" class="uk-grid-divider" uk-grid>
          <div class="uk-width-1-2">
          <div class="uk-flex uk-flex-between">
           <h4 class="uk-text-left">{{ $t('Pages.Content.Ar.MediaList') }}</h4>
            <button class="uk-button uk-button-primary edit-media-list uk-flex-right" v-if="isMutable" @click="openArMediaPicker()">
              <span class="uk-margin-small-right" uk-icon="icon: pencil; ratio: 0.7"></span> {{ $t('Pages.Content.Ar.MediaList') }}
            </button>
          </div>
            <ul
              v-for="(media, i) in formattedArMediaList" 
              :key="i"
              class="uk-list uk-list-striped">
              <li class="uk-card uk-card-defaults uk-padding-remove">
                <div class="uk-grid-small uk-flex-middle" uk-grid>
                  <div class="uk-width-small" style="padding:10px;">
                      <ImageOrFallback v-if="media.thumbnail"  width="85px" height="40px" object-fit="contain" :src="media.thumbnail.url" />
                      <MediaItemImageFallback v-else :media="media"  width="85px" height="40px" object-fit="contain" />
                  </div>
                  <div class="uk-width-expand">
                    <router-link v-if="isMutable" :to="media.mediaClass === 'VIDEO' ? { name: 'MediaBundle', params: { id: id, bundleId: media.bundle ? media.bundle.id : media.id } } : { name: 'MediaItem', params: { id: id, mediaId: media.id } }"  class="uk-float-left uk-text-left uk-width-3-4">
                      <p class="uk-link-heading uk-margin-remove-bottom uk-width-medium uk-text-truncate" >{{ media.name }}</p>
                    </router-link>
                     <div v-else  class="uk-float-left uk-text-left uk-width-3-4">
                      <p class="uk-link-heading uk-margin-remove-bottom uk-width-medium uk-text-truncate" >{{ media.name }}</p>
                    </div>
                    <div class="uk-width-auto uk-float-left">
                      <p class="uk-link-heading uk-margin-remove-bottom">{{ $t( `enums.MediaClass.${media.mediaClass}` )  }}</p>
                    </div>
                     <div class="uk-float-right uk-width-auto" v-if="isMutable">
                      <span class="uk-link-heading uk-margin-remove-bottom uk-margin-right" uk-icon="icon: trash; ratio:.8" style="cursor:pointer"  :uk-tooltip="$t('Actions.Delete')" @click="handleMediaListItemDelete(media.id)" />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="uk-text-center" v-if="formattedArMediaList.length === 0 && !contentFetching">
              <span class="uk-text-meta uk-text-warning  uk-text-italic">{{ $t('Pages.Content.Ar.MediaListCantBeEmpty') }}</span>
            </div>
          </div>
          <div class="uk-width-1-2">
            <div class="uk-flex uk-flex-between">
              <h4 class="uk-text-left">{{ $t('Pages.Content.Ar.TargetImages') }}</h4>
              <button class="uk-button uk-button-primary edit-media-list uk-flex-right" v-if="isMutable" @click="openArMediaPicker('IMAGE')">
                <span class="uk-margin-small-right" uk-icon="icon: pencil; ratio: 0.7"></span> {{ $t('Pages.Content.Ar.TargetImages') }}
              </button>
            </div>
            <div class="uk-margin-top" v-if=" ar.targetImages.length">
            <ul
              v-for="(image, i) in formattedArTargetImages" 
              :key="i"
              class="uk-list uk-list-striped"
            >
              <li class="uk-card uk-card-defaults uk-padding-remove">
                <div class="uk-grid-small uk-flex-middle" uk-grid>
                  <div class="uk-width-small" style="padding:10px;">
                    <MediaItemImageFallback :media="image" width="85px" height="40px" object-fit="contain" />
                  </div>
                  <div class="uk-width-expand">
                     <router-link v-if="isMutable" :to="{ name: 'MediaItem', params: { id: id, mediaId: image.id } }"  class="uk-float-left uk-text-left uk-width-3-4">
                      <p class="uk-link-heading uk-margin-remove-bottom uk-width-medium uk-text-truncate" >{{ image.name }}</p>
                    </router-link>
                    <div  v-else class="uk-float-left uk-text-left uk-width-3-4">
                      <p class="uk-link-heading uk-margin-remove-bottom uk-width-medium uk-text-truncate" >{{ image.name }}</p>
                    </div>
                    <div class="uk-width-auto uk-float-left">
                      <p class="uk-link-heading uk-margin-remove-bottom">{{ $t( `enums.MediaClass.${image.mediaClass}` ) }}</p>
                    </div>
                     <div class="uk-float-right uk-width-auto" v-if="isMutable">
                      <span class="uk-link-heading uk-margin-remove-bottom uk-margin-right" uk-icon="icon: trash; ratio:.8" style="cursor:pointer" :uk-tooltip="$t('Actions.Delete')" @click="handleTargetImageItemDelete(image.id)"/>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            </div>
            <div class="uk-text-center" v-else>
              <span class="uk-text-meta">{{ $t('Pages.Content.Ar.NoTargetImages') }}</span>
            </div>
          </div>

        </div>
        <div v-if="isTypeArItem && showArSaveBtn" class="uk-flex uk-flex-center" style ="margin-top:40px">
            <button class="uk-button uk-button-primary" :disabled="isArSaveBtnDisabled || ar.mediaList.length === 0" @click="updateAr()">
               <span  uk-spinner ratio="0.5" v-if="isArSaveBtnDisabled"></span> 
               <span v-else>{{ $t('Pages.Content.Ar.SaveChanges') }}</span>
            </button>
        </div>




      
        <div  v-if="!isTypeArItem" class="uk-grid-divider" uk-grid>
          <div class="uk-width-3-5">
            <div v-if="isBannerAd(contentItem)" class="uk-margin-small-bottom">
              <ImageOrFallback :src="keyArtUrl" height="450px" width="100%"/>
            </div>
            <div v-else-if="isVideoOrPromotedAd(contentItem)" class="uk-margin-small-bottom">
              <template v-if="isMediaReady(adContentUrl)" >
                <video v-if="isHls(adContentUrl)" ref="video" controls class="image-tile"/>
                <video v-else-if="isImage" :src="videoUrl" :type="adContentUrl.mediaType" :poster="videoUrl" class="image-tile"/>
                <div v-else-if="!adContentUrl.file" class="video-not-ready image-tile uk-flex uk-flex-column uk-flex-center">
                    <p class="uk-text-danger">{{ $t( 'Player.FilePathMissing' ) }}</p>
                </div>
                <video v-else controls :src="videoUrl" :type="adContentUrl.mediaType" class="image-tile"/>
              </template>
              <div v-else-if="isMediaError(adContentUrl)" class="video-not-ready image-tile uk-flex uk-flex-column uk-flex-center">
                  <p class="uk-text-danger">{{ $t( 'Player.TranscodeError' ) }}</p>
              </div>
              <div v-else class="video-not-ready image-tile uk-flex uk-flex-column uk-flex-center">
                <p class="uk-text-danger">{{ $t( 'Player.PreviewIsUnavailable' ) }}</p>
              </div>
            </div>
            <IconicPlayer v-if="isContentVideoOrLive(contentItem)" class="uk-margin-small-bottom uk-text-left uk-flex image-tile" :content="contentItem" :org-id="id"/>
            <span class="uk-float-left uk-text-meta">{{ $t( `enums.ContentType.${contentItem.type}` ) }} {{$t( 'Pages.Application.PushNotificationForm.Content' )}}</span>
            <router-link :to="{ name: 'MediaBundle', params: { id: id, bundleId: mediaBundle } }" class="uk-link uk-float-right uk-margin-left download" v-if="isContentVideoOrLive(contentItem)&&!isTypeInteractive(contentItem)&&mediaBundle&&isMutable">{{ $t('Pages.Content.SourceMedia') }}</router-link>
            <router-link :to="{ name: 'InteractiveVideoDetail', params: { id: id, ivId: contentItem.ref.interactive?contentItem.ref.interactive.id:'' } }" class="uk-link uk-float-right download" v-if="isTypeInteractive( contentItem ) && isMutable">{{ $t('Player.EditInteractive') }}</router-link>
            <a :href="editorLink" class="uk-link uk-float-right uk-margin-right download" v-if="isTypeInteractive( contentItem ) && isMutable">{{ $t('Player.EditorApp') }}</a>
            <a class="uk-link uk-float-right uk-margin-right download" v-if="!isBannerAd(contentItem) && isMutable" href="#embed-code-modal" uk-toggle>{{ $t('Player.EmbedCode') }}</a>
          </div>
          <div class="uk-width-2-5">
            <div class="uk-align-center uk-width-4-5">
              <ImageOrFallback :src="keyArtUrl" height="190px" width="100%" imageClass='uk-border-rounded'/>
              <div uk-grid class="uk-margin-small-top">
                <p class="uk-text-left uk-width-expand">{{ $t('Pages.Content.KeyArt') }}</p>
                <div v-if="isMutable && (isContentVideoOrLive(contentItem)||isTypeInteractive(contentItem))" uk-icon="icon: pencil; ratio: 1" :uk-tooltip="$t('Breadcrumbs.edit')" class="uk-float-right uk-margin-small-right uk-link" @click="openMediaPicker('key')" />
                <div v-if="isMutable && (isContentVideoOrLive(contentItem)||isTypeInteractive(contentItem))" uk-icon="icon: trash; ratio: 1" :uk-tooltip="$t('Actions.Delete')" class="uk-float-right uk-padding-remove-left uk-link" @click="deleteContentArt('key')" :class="{'disable': !keyArtUrl}" />
              </div>
            </div>
            <div class="uk-align-center uk-width-4-5">
              <ImageOrFallback :src="tileArtUrl" height="190px" width="100%" imageClass='uk-border-rounded' />
              <div uk-grid class="uk-margin-small-top">
                <p class="uk-text-left uk-width-expand">{{ $t('Pages.Content.TileArt') }}</p>
                <div v-if="isMutable && (isContentVideoOrLive(contentItem)||isTypeInteractive(contentItem))" uk-icon="icon: pencil; ratio: 1" :uk-tooltip="$t('Breadcrumbs.edit')" class="uk-float-right uk-margin-small-right uk-link" @click="openMediaPicker('tile')" />
                <div v-if="isMutable && (isContentVideoOrLive(contentItem)||isTypeInteractive(contentItem))" uk-icon="icon: trash; ratio: 1" :uk-tooltip="$t('Actions.Delete')" class="uk-float-right uk-padding-remove-left uk-link" @click="deleteContentArt('tile')" :class="{'disable': !tileArtUrl}" />
              </div>
            </div>
          </div>
        </div>
        <div id="embed-code-modal" container="#content-details" uk-modal="bg-close: false; esc-close: false; stack: true">
          <div class="uk-modal-dialog uk-margin-auto-vertical">
            <div class="uk-modal-header uk-text-left">
              <h3>{{ $t('Player.CopyPasteEmbedHTML')}}</h3>
            </div>
            <div class="uk-modal-body uk-flex uk-flex-column">
              <div class="uk-form-controls">
                <textarea class="uk-textarea" 
                  rows="3"
                  type="text"
                  ref="text"
                  :value="videoIFrameEmbed"/>
              </div>
            </div>
             <div class="uk-modal-footer uk-text-right">
              <button class="uk-button uk-button-default uk-modal-close uk-margin-right">{{ $t('Actions.Close') }}</button>
              <button class="uk-button uk-button-primary" @click="copyEmbedUrl">{{ $t('Player.CopyToClipboard') }}</button>
            </div>
          </div>
        </div>
        
        <div v-if="showPicker" id="media-picker-art-modal" container="#content-details" uk-modal>
          <div class="uk-modal-dialog uk-modal-body uk-width-3-4">
            <ModalMediaPicker media-class-filter="IMAGE" @cancel="closeModal()" @select="selectedArt( $event )" />
          </div>
        </div>
          <!-- ar media picker modal -->
        <div v-if="showArPicker" id="media-ar-picker-modal" container="#content" uk-modal="bg-close: false; esc-close: false; stack:true;">
          <div class="uk-modal-dialog uk-modal-body uk-width-3-4">
              <ModalMediaArPicker :media-class-filter="contentType" :selectedContentFiles="selectedFiles" @selectArs="selectedArs( $event )"/>
          </div>
        </div>
        <!-- ar modal ends  -->


      </li>
      <li v-if="isMutable" id="languages"><content-languages :content="contentItem"/></li>
      <li v-if="isMutable" id="availability"><content-availability :content="contentItem" @formChanged="updateFormChange('contentAvailabilityUnsaved', $event)" /></li>
      <li v-if="isTypeVOD && isMutable"><content-formats :content="contentItem" :org-id="id"/></li>
      <li id="subtitles" v-if="isTypeVOD && isMutable"> <ContentSubtitles :content="contentItem"/> </li>
      <li v-if="isTypeVOD && isMutable"><app-assets :content="contentItem" /></li>
      <li id="cameras" v-if="isTypeStream && isMutable"><content-cameras :content="contentItem" :org-id="id"/></li>
      <li v-if="!isTypeArItem && !isTypeAdItem && isMutable && (featureSupport && featureSupport.ads)"><content-ads :content="contentItem" @formChanged="updateFormChange('contentAdsUnsaved', $event)" /></li>
      <li id="permissions " v-if="isMutable"><ContentPermissions :content="contentItem" @formChanged="updateFormChange('contentPermissionsUnsaved', $event)"/></li>
      <li id="information" v-if="isTypeVOD"><ContentInformation :content="contentItem"/> </li> 
     </ul>
     <div v-if="contentFetching && (contentItem.id !== contentId)"><span uk-spinner></span></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import IconicPlayer from '@/components/IconicPlayer';
import { getContentTitle, isBannerAd, isVideoOrPromotedAd, isTypeInteractive, isContentVideoOrLive } from '@/utils/content';
import { playerDirectUrl } from '@/utils/player';
import ImageOrFallback from '@/components/images/ImageOrFallback';
import { isMediaReady, isMediaError } from '@/utils/player';
import { iv } from '@/app.config';
import ContentLanguages from './ContentLanguages';
import ContentCameras from './ContentCameras';
import ContentSubtitles from './ContentSubtitles';
import { ContentType, Permissions } from '@/utils/enums';
import ModalMediaPicker from '@/components/modals/ModalMediaPicker.vue';
import Notification from '@/components/util/Notification';
import AppAssets from '../AppAssets';
import ContentAvailability from './ContentAvailability';
import ContentFormats from './ContentFormats';
import ContentPermissions from './ContentPermissions';
import ContentAds from './ContentAds';
import ContentInformation from './ContentInformation';
import MediaItemImageFallback from '@/views/admin/mediaLibrary/MediaItemImageFallback';

export default {
  name: 'ContentDetails',
  components: { IconicPlayer, ImageOrFallback, MediaItemImageFallback, ContentLanguages, ContentCameras, ModalMediaPicker, AppAssets, ContentAvailability, ContentFormats, ContentSubtitles, ContentPermissions, ContentAds, ContentInformation ,
  ModalMediaArPicker:()=> import('@/components/modals/ArPicker.vue'),
},

props: {
    propertyId: { type: String, default: '' },
    contentId: { type: String, default: '' },
    id: { type: String, default: '' }
  },
  data: () => ({
    mutationRoles: [Permissions.ADMIN, Permissions.CONTENT_MANAGER],
    getContentTitle,
    isBannerAd,
    iv,
    isVideoOrPromotedAd,
    isTypeInteractive,
    isContentVideoOrLive,
    isMediaError,
    isMediaReady,
    showPicker: false,
    artType: '',
    contentAvailabilityUnsaved: false,
    contentPermissionsUnsaved: false,
    contentAdsUnsaved: false,
    contentFetching: false,
    contentType: "",
    showArPicker:false,
    selectedFiles:[],
    ar:{
      mediaList:[],
      targetImages:[],
    },
    showArSaveBtn:false,
    isArSaveBtnDisabled:false

  }),
  computed: {
    ...mapState({
      viewer: state => state.venom.org.viewer,
      contentItem: state => state.venom.org?.contentItem || {},
      featureSupport: state => state.venom.org.options?.featureSupport
    
    }),
    keyArtUrl () {
      return this.contentItem.type === "AD" ? (this.contentItem?.art && this.contentItem.art['key']?.url) || this.contentItem?.ref?.ad?.banner?.url : this.contentItem?.art && this.contentItem.art['key']?.url;
    },
    tileArtUrl () {
      return this.contentItem.type === "AD" ? (this.contentItem?.art && this.contentItem.art['tile']?.url) || this.contentItem?.ref?.ad?.logo?.url : this.contentItem?.art && this.contentItem.art['tile']?.url;
    },
    adContentUrl () {
      return this.contentItem?.ref?.ad?.video;
    },
    videoUrl () {
      if ( this.adContentUrl?.transcodeType === 'CLEARVR' )
        return this.adContentUrl.transcodeSource?.file?.url;
      return this.adContentUrl?.file?.url;
    },
    isImage () {
      const url = this.adContentUrl?.transcodeType === 'CLEARVR' ? this.adContentUrl.transcodeSource?.file?.url : this.adContentUrl?.file?.url;
      return url && url.match( /\.(jpeg|jpg|gif|png)$/ ) != null;
    },
    editorLink () {
      return this.contentItem.ref?.interactive?this.iv.editor + '/?load_story=' + this.contentItem.ref.interactive.id : "";
    },
    videoIFrameEmbed () {
      return `<iframe src="${playerDirectUrl(this.contentItem, this.$store.playerToken )}" width="100%" height="100%" frameborder="0" title="Content Item" />`;
    },
    isTypeVOD () {
      return this.contentItem.type === ContentType.VOD;
    },
    isTypeStream () {
      return this.contentItem.type === ContentType.STREAM;
    },
    isTypeAdItem () {
      return this.contentItem.type === ContentType.AD;
    },
    isTypeArItem () {
      return this.contentItem.type === ContentType.AR;
    },
    contentArtType () {
      return this.artType === 'tile'? this.$t('Pages.Content.TileArt') : this.$t('Pages.Content.KeyArt');
    },
    propertyMap () {
      const prop = {} 
      this.viewer?.permissions?.forEach(perm => {
        prop[perm.property.id] = this.isAdmin ? Permissions.ADMIN : perm.type
      })
      return prop
    },
    isAdmin () {
      return this.viewer?.role === Permissions.ADMIN ? true : false
    },
    isMutable () {
      return this.isAdmin || this.mutationRoles.includes(this.propertyMap[this.propertyId])
    },
    isAnyAdmin () { //Admin to any folder
      if (this.isAdmin) return true
      else {
        let isAdmin = false
        for (const perm of this.viewer?.permissions) {
          if (Permissions.ADMIN === perm.type) {
            isAdmin = true
            break
          }
        }
        return isAdmin
      }
    },
    mediaBundle () {
      const { primaryFormat } = this.contentItem?.ref;
      return primaryFormat? ( primaryFormat?.media?.bundle?.id || this.contentItem?.ref?.formats[0]?.media?.bundle?.id) : this.contentItem?.ref?.cameras[0]?.media?.bundle?.id;
    },
    formattedArMediaList(){
      return this.ar.mediaList.map((item)=>{
        if(item.__typename==="Media" && !item.name){
          return {...item,name:item.title}
        }else if(item.__typename==="MediaBundle") {
          //mediabundle
          return { ...item.masterVideo,id:item.id,name:item.name }
        }
        return item; 
      })
    },
    formattedArTargetImages(){
      return this.ar.targetImages.map((item)=>{
        if(item.__typename==="Media" && !item.name){
          return {...item,name:item.title}
        }
        return item; 
      })
    }
  },
  methods: {
    navigateBack(){
      let contentIds = this.contentItem.parents;
      let collectionId = contentIds[0].id;
      let propertyId = contentIds[1].id;

      this.$router.push(`/organizations/${this.id}/publishing/${propertyId}/collection/${collectionId}`);
    },
    updateFormChange (key, event) {
      if (typeof event === 'boolean') {
        this[key] = event
      }
    },
    async getContentItem() {
      this.contentFetching = true;
      await this.$store.dispatch('getContentItem', { contentId: this.contentId });
      this.contentFetching = false;
    },
    isHls ( videoInfo ) {
      return videoInfo.mediaType === 'application/x-mpegURL';
    },
    canPlayHls ( videoElem ) {
      return videoElem.canPlayType( 'application/vnd.apple.mpegurl' );
    },
    hlsReady ( Hls ) {
      const { video } = this.$refs;
      const { url } = this.adContentUrl.file || {};
      if ( this.canPlayHls( video ) ) {
        video.src = url;
      } else if ( Hls && Hls.isSupported ) {
        const hls = new Hls();
        hls.loadSource( url );
        hls.attachMedia( video );
      }
    },
    loadHlsJs ( fn ) {
      const scriptElem = document.createElement( 'script' );
      scriptElem.onload = () => { fn( window.Hls ); };
      scriptElem.src = '//cdnjs.cloudflare.com/ajax/libs/hls.js/0.12.2/hls.min.js';
      document.head.appendChild( scriptElem );
    },
    copyEmbedUrl () {
      this.$refs.text.select();
      document.execCommand('copy');
      Notification.showNotification(this.$t( 'Generic.Messages.Successful'), this.$t( 'Pages.Content.CodeCopied'));
    },
    openMediaPicker ( type ) {
      this.showPicker = true;
      this.artType = type;
      setTimeout( () => {
        window.UIkit.modal('#media-picker-art-modal').show();
      }, 100 );
    },
    async selectedArt ( media ) {
      await this.$store.dispatch('updateContentArt', {
        content: this.contentItem.id,
        variables: { key: this.artType === 'key' ? { id: media.id } : null, tile: this.artType === 'tile' ? { id: media.id } : null } 
      });
      this.closeModal();
    },
    closeModal () {
      window.UIkit.modal('#media-picker-art-modal').hide();
      setTimeout( function() {
        this.showPicker = false;
      }, 10 );
      this.artType = '';
    },
    async deleteContentArt( type ) {
        this.artType = type;
        const UIkit = window.UIkit;
        UIkit.modal.confirm(this.$t( 'DeleteModal.ConfirmDeleteContentArt', { name: this.contentArtType } ), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }}).then( async () => {
          const response = await this.$store.dispatch( 'deleteContentArt', { 
            content: this.contentItem.id,
            variables: { key: this.artType === 'key', tile: this.artType === 'tile'} 
          });
          if ( response ) {
            Notification.showNotification(this.$t( 'DeleteModal.Removed'), this.$t( 'DeleteModal.HasBeenRemoved', { name: this.contentArtType }));
          } else {
            Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), response?.errors[0]?.message, 'error');
          }
        },()=>{});
      },
    
    openArMediaPicker ( type) {
      this.contentType = type;
      this.showArPicker = true;
      if(type === "IMAGE"){
        this.selectedFiles = this.ar.targetImages.map(item=>{
         if(item.bundle?.id){
          return {...item,id:item.bundle.id}
         }
          return item
         
        });
      }else{

          this.selectedFiles= this.ar.mediaList.map(item=>{
         if(item.bundle?.id){
          return {...item,id:item.bundle.id}
         }
          return item
         
        });
        
      }
      setTimeout( () => {
        window.UIkit.modal('#media-ar-picker-modal').show();
      }, 100 );
    },

    async selectedArs ( arArr ) {
      this.showArSaveBtn=true;
      if(this.contentType=="IMAGE"){
        this.ar.targetImages = arArr;
      }else{
      this.ar.mediaList = arArr;
      }
      window.UIkit.modal('#media-ar-picker-modal').hide();
    },

    handleMediaListItemDelete(id){
      this.showArSaveBtn=true;
      this.ar.mediaList = this.ar.mediaList.filter((item)=>item.id !== id);
    },
    handleTargetImageItemDelete(id){
       this.showArSaveBtn=true;
       this.ar.targetImages = this.ar.targetImages.filter((item)=>item.id !== id);
    },
    async updateAr(){
      let mediaList = this.ar.mediaList.map(item=>item.id);
      let targetImages = this.ar.targetImages.map(item=>item.id);
      this.isArSaveBtnDisabled= true;
      const response = await this.$store.dispatch( 'updateArContent', { 
            ar: this.contentItem.id,
            variables: { mediaList,targetImages} 
          });
          if ( response ) {
            this.isArSaveBtnDisabled= false;
            this.showArSaveBtn=false;

            Notification.showNotification(this.$t( 'UpdateModal.Updated'), this.$t( 'UpdateModal.HasBeenUpdated', { name: "AR Content" }));
          } else {
            this.isArSaveBtnDisabled= false;
            Notification.showNotification(this.$t( 'UpdateModal.NotUpdated'), response?.errors[0]?.message, 'error');
          }
    }
    

  },
  async beforeMount() {
    await this.getContentItem();
    if ( isVideoOrPromotedAd( this.contentItem ) && isMediaReady( this.adContentUrl ) && this.isHls( this.adContentUrl ) ) {
      if ( this.canPlayHls( this.$refs.video ) )
          this.hlsReady();
      else
        this.loadHlsJs( this.hlsReady );
    }
  },

  watch:{
     contentItem(){
   this.ar = {
      mediaList :this.$store.state.venom.org?.contentItem?.ref.mediaList,
      targetImages:this.$store.state.venom.org?.contentItem?.ref.targetImages
      }
  }
  },

}
</script>

<style lang="scss" scoped>
.back-link {
  cursor: pointer;
}
.image-tile {
    width: 100%;
    height: 450px;
}
.video-not-ready {
  background: rgba(0, 0, 0, 0.3);
}
.download {
  color: var(--app-primary-color);
}
.disable {
  pointer-events: none;
}

.uk-text-truncate {
  color:var(--app-primary-color);
  cursor:pointer;
}
.uk-text-truncate:hover {
    color:var(--text-color);
}
.edit-media-list {
  padding: 0 10px;
  height: 40px;
}
</style>
