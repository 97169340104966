<template>
  <div id="content-cameras">
    <div class="uk-left-right uk-margin-bottom">
      <router-link type="button" :to="{ name: 'MediaBundle', params: { id: orgId, bundleId: mediaBundle } }" class="uk-link uk-float-left source-media uk-margin-bottom">{{ $t('Pages.Content.SourceMedia') }}</router-link>
    </div>
    <div class="uk-float-right">
      <button class="uk-button uk-button-primary add-camera-btn" v-if="uncoupledMedia.length>0" @click="openMediaPicker()">
        <span uk-icon="plus-circle" ></span> {{ $t( 'Pages.MediaLibrary.AddCamera' ) }}
      </button>
    </div>
    <div 
      class="uk-grid-small uk-width-expand uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl uk-margin-medium-top"
      uk-scrollspy="target: > div.uk-grid-item-match; cls: uk-animation-scale-up; delay: 50; offset-top: 2000;"
      uk-grid
      >
      <div v-for="(camera, i) in contentCameras" :key="i" class="uk-grid-item-match">
          <div class="uk-card uk-card-default uk-card-small uk-text-left uk-card-hover uk-border-rounded">
            <div class="card-body-max-height uk-padding-small uk-padding-remove-bottom uk-padding-remove-top">
              <div class="uk-text-center">
                <div uk-icon="icon: video-camera; ratio: 3"/>
                <h5 class="uk-card-title uk-margin-remove-top uk-margin-remove-bottom uk-text-truncate title-font" style="font-size:1rem">{{cameraTitle( camera )}}</h5>
                <p class="uk-margin-remove uk-text-truncate">{{ $t( `enums.ProjectionType.${cameraProjection( camera )}` ) }}</p>
                <p class="uk-margin-remove-top"> {{ mediaType(camera) }} </p>
              </div>
            </div>
            <div class="uk-card-footer">
              <div class="uk-text-meta uk-float-right">
                <span v-if="deletingList.includes( camera )" uk-spinner="ratio: 0.7" />
                <div v-else-if="!camera.isDefault" uk-icon="trash" id=js-modal-confirm uk-tooltip="Delete" class="uk-link" @click="deleteCamera( camera )"></div>
              </div>
              <div class="uk-text-meta uk-float-right uk-margin-small-right">
                <div uk-icon="pencil" class="uk-link" uk-tooltip="Edit" @click="editCameraDetails( camera )" href="#edit-camera-modal" uk-toggle></div>
              </div>
              <div class="uk-flex-inline" :class="{'disable': camera.isDefault}">
                <span v-if="camera.isDefault" class="uk-label uk-label-success">{{ $t( 'Pages.Content.PrimaryCamera' ) }}</span>
                <button v-else class="make-primary" type="button" :disabled="isLoading" @click="setPrimary(camera, true)"> {{ $t( 'Pages.Content.MakePrimary' ) }}</button>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div container="#content-cameras" id="edit-camera-modal" uk-modal="bg-close: false; esc-close: false">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <div class="uk-modal-header">
          <h3 class="uk-text-left uk-modal-title"> {{ $t( 'Pages.Content.EditCamera' ) }} </h3>
        </div>
        <div class="uk-modal-body uk-flex uk-flex-column">
          <div class="uk-form-stacked uk-text-left">
            <button class="uk-modal-close-default" type="button" uk-close @click="resetForm()"></button>
            <div class="uk-margin">
              <label class="uk-form-label"> {{ $t('Pages.MediaLibrary.Labels.Title') }} <span class="uk-text-danger">*</span></label>
              <div class="uk-inline uk-width-1-1">
                <input class="uk-input uk-text-truncate"
                :class="{ 'invalid-border': !form.title || form.titleError }"
                 type="text"
                :placeholder="$t('Pages.MediaLibrary.Placeholders.Title')" 
                @input="validateTitle($event.target.value)"
                v-model.trim="form.title" />
                <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!form.title || form.titleError" uk-icon="icon: warning" ></span>
                <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!form.title || form.titleError" >{{form.titleError || 'Title should be atleast 3 characters'}}</span>
              </div>
            </div>
            <div class="uk-flex uk-width-1-1 uk-margin">
              <div class="uk-width-1-2">
                <label class="uk-form-label"> {{ $t('Pages.MediaLibrary.Labels.ProjectionType') }} </label>           
                <div class="uk-margin-small-top uk-margin-small-right">
                  <p class="uk-text-meta uk-margin-remove-top">{{ $t( `enums.ProjectionType.${form.projection}` ) }}</p>
                </div>
              </div>
              <div v-if="!isEditCameraPrimary" class="uk-width-1-2">
                <div class="uk-width-1-2 uk-margin-left">
                  <label class="uk-form-label">{{ $t('Pages.Content.Active') }}</label>
                  <ToggleButton class="uk-margin-small-top" :value="form.active" @toggle="form.active = $event" />
                </div>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label"> {{ $t('Pages.MediaLibrary.Labels.SourceURL') }}</label>
              <div>
                <p class="uk-text-meta uk-text-break uk-width-large">{{ editCamera ? editCamera.media.file.url : '' }} </p>
              </div>
            </div>
            <div class="uk-flex uk-margin">
              <div class="uk-width-1-2">
                <label class="uk-form-label"> {{ $t('Pages.Content.CameraType') }} </label>           
                <p class="uk-text-meta uk-margin-remove-top">{{ mediaType(editCamera) }} </p>
              </div>
              <div class="uk-width-1-2">
                <label class="uk-form-label"> {{ $t('Pages.MediaLibrary.Labels.Blindspot') }} </label>
                <p class="uk-text-meta uk-margin-remove-top">{{ $t( `enums.BlindspotType.${blindSpot(editCamera)}` ) }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-width-1-1 uk-text-right">
           <button class="uk-button uk-button-default uk-modal-close uk-margin-right" @click="resetForm()">{{ $t('Actions.Cancel') }}</button>
          <button v-if="!isLoading" class="uk-button uk-button-primary" :disabled="isDisabled" @click="onSubmit()">{{ $t('Actions.Save') }}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
    </div>
     <div v-if="showPicker" id="media-picker-modal-cameras" container="#content-cameras" uk-modal>
      <div class="uk-modal-dialog uk-modal-body uk-width-3-4">
        <ModalMediaPicker media-class-filter="LIVE" :media-list="uncoupledMedia" @cancel="closeModal()" @select="selectedCamera( $event )" />
      </div>
  </div>
</div>
</template>

<script>
import ModalMediaPicker from '@/components/modals/ModalMediaPicker.vue';
import ToggleButton from '@/components/buttons/ToggleButton.vue';
import { ProjectionType, BlindspotType } from '@/utils/enums';
import Notification from '@/components/util/Notification';
import { getContentStreamBundleMediaUncoupled } from '@/utils/content';

export default {
  name: 'ContentCameras',
  components: { ToggleButton, ModalMediaPicker },
  props: {
    content: { type: Object, required: true },
    orgId: { type: String, required: true }
  },
  data () {
    return {
      form: {
        title: '',
        projection: '',
        titleError: '',
        active: false,
        blindspot: ''
      },
      editCamera: null,
      BlindspotType,
      ProjectionType,
      deletingList: [],
      isLoading: false,
      showPicker: false,
      uncoupledMedia: []
    }
  },
  computed: {
    mediaBundle () {
      return this.content?.ref?.cameras[0]?.media?.bundle?.id;
    },
    contentCameras() {
      return this.content.ref.cameras || '';
    },
    isDisabled() {
      return !this.form.title || this.form.title.trim().length<3 || ( this.form.title && this.form.title.length<3 );
    },
    isEditCameraPrimary () {
      return this.editCamera?.isDefault || false;
    }
  },
  methods: {
    cameraTitle ( camera ) {
      return camera.primaryLanguage.title || camera.media.title;
    },
    cameraProjection ( camera ) {
      return camera.media?.projection || camera.video?.projection || '';
    },
    validateTitle( title ) {
      if ( title ) {
        this.form.titleError = title.length<3 || !title.trim() ? 'Title should be atleast 3 characters': '';
      }
    },
    editCameraDetails( camera ) {
      this.editCamera = camera;
      this.form.title = this.cameraTitle( camera );
      this.form.projection = this.cameraProjection( camera );
      this.form.active = camera.active;
    },
    resetForm() {
      this.form.title = this.cameraTitle( this.editCamera );
      this.form.projection = this.cameraProjection( this.editCamera );
      this.form.titleError = '';
      this.form.active = false;
    },
    mediaType( camera ) {
      if ( !camera || camera.media.mediaType === null)
        return 'None';
      return camera.media.mediaType;
    },
    blindSpot( camera ) {
      return camera?.media?.blindspotType;
    },
    async deleteCamera( camera ) {
        const UIkit = window.UIkit;
        UIkit.modal.confirm(this.$t( 'DeleteModal.ConfirmDeleteContentArt', { name: this.cameraTitle(camera) })).then( async () => {
          this.deletingList.push( camera );
          const response = await this.$store.dispatch( 'deleteContentCamera', { cameraId: camera.id, content: this.content.id });
          if ( response?.deleteCamera ) {
            this.deleteCameraRef( camera );
            Notification.showNotification(this.$t( 'DeleteModal.Removed'), this.$t( 'DeleteModal.HasBeenRemoved', { name: this.cameraTitle( camera ) }));
          } else {
            Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), response?.errors[0]?.message, 'error');
          }
        }, () => {
          this.deleteCameraRef( camera );
        });
        setTimeout( () => {
          const modalEl = document.querySelector(".uk-modal:not([id])");
          modalEl.style.display = "block";
          modalEl.classList.add("uk-open");
        }, 100 );
    },
    deleteCameraRef ( camera ) {
      this.deletingList.forEach( i=> {
        if( i.id == camera.id )
          this.deletingList.splice(i,1);
      });
    },
    async onSubmit ( ) {
      this.isLoading = true;
      const camera = await this.$store.dispatch('updateContentCamera', {
        camera: this.editCamera, title: this.form.title, active: this.form.active, blindSpot: this.form.blindSpot, content: this.content.id
      });
      this.isLoading = false;
      window.UIkit.modal('#edit-camera-modal').hide();
      this.resetForm();
      if ( !camera.errors ) {
        await this.$store.dispatch('getContentItem',{contentId: this.content.id});
        Notification.showNotification(this.$t( 'Generic.Messages.Successful'), this.$t( 'Pages.Content.UpdateCamera'));
      }
      else 
        Notification.showNotification(this.$t( 'Generic.Messages.UnSuccessful'), camera?.errors[0]?.message,'error');
    },
    async setPrimary( camera, isPrimary ) {
      this.isLoading = true;
      const contentCamera = await this.$store.dispatch('updateContentCamera', {
          setToDefault: isPrimary,
          camera,
          content: this.content.id
      });
      this.isLoading = false;
      if( contentCamera?.updateCamera ) {
        await this.$store.dispatch('getContentItem',{contentId: this.content.id});
        Notification.showNotification(this.$t( 'Generic.Messages.Successful'), this.$t( 'Pages.Content.UpdateCamera'));
      }
      else 
        Notification.showNotification(this.$t( 'Generic.Messages.UnSuccessful'), contentCamera?.errors[0]?.message, 'error');
    },
    openMediaPicker () {
      this.showPicker = true;
      setTimeout( () => {
        window.UIkit.modal('#media-picker-modal-cameras').show();
      }, 100 );
    },
    closeModal () {
      window.UIkit.modal('#media-picker-modal-cameras').hide();
      setTimeout( function() {
        this.showPicker = false;
      }, 100 );
    },
    async getContentLiveBundleMediaUncoupled ( contentVOD ) {
      if ( contentVOD ) {
        const primaryFormatMedia = contentVOD?.ref?.cameras[0]?.media;
        if ( primaryFormatMedia?.bundle?.id ) {
          const bundle = await this.$store.dispatch('fetchMediaBundle', { bundleId: primaryFormatMedia?.bundle?.id  } );
          return getContentStreamBundleMediaUncoupled( contentVOD, bundle.media );
        }
      }
      return [];
    },
    async selectedCamera ( media ) {
      if ( media  ) {
        const newCamera= await this.$store.dispatch( 'createContentCamera', { content: this.content.id, media: media.id });
        this.closeModal();
        if ( !newCamera.errors ) {
          Notification.showNotification( this.$t( 'Generic.Messages.Successful' ), this.$t( 'Pages.Content.CreateCamera' ) );
        }
        else 
          Notification.showNotification(this.$t( 'Generic.Messages.UnSuccessful'), newCamera?.errors[0]?.message,'error');
      }
    }
  },
  async mounted () {
    this.uncoupledMedia = await this.getContentLiveBundleMediaUncoupled( this.content );
  },
  watch: {
    async content () {
      this.uncoupledMedia = await this.getContentLiveBundleMediaUncoupled( this.content );
    }
  },
}
</script>

<style scoped>
.disable {
  pointer-events: none;
}
.add-camera-btn {
  padding: 0 20px;
}
.source-media {
  color: var(--app-primary-color);
}
.make-primary {
  background-color: var(--app-primary-color);
  border: none;
  color: white;
  border-radius: 16px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
</style>
