<template>
<div id="content-pre-post-roll">
    <div class="uk-width-1-2 uk-margin-left uk-text-left">
      <div class="uk-flex-inline">
        <h4 class="uk-text-left uk-text-bold">{{ $t('Pages.Content.Ads.PreRoll') }}</h4>
        <span v-if="formChanged" class="uk-margin-small-left uk-text-danger">{{$t('Generic.Labels.Unsaved')}}</span> 
      </div>
      
      <div class="uk-text-left">
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label><input class="uk-radio" type="radio" name="pre-roll" value="DISABLED" v-model="preRollType"> {{ $t('Pages.Content.Ads.DisableAds')}} </label>
            <label v-if="content.type==='VOD'"><input class="uk-radio" value="AUTO" type="radio" name="pre-roll"  v-model="preRollType"> {{ $t('Pages.Content.Ads.AutomatedAds')}} </label>
            <label v-else><input class="uk-radio" type="radio" name="pre-roll" disabled value="AUTO" :uk-tooltip="$t('Pages.Content.Ads.NotSupportedAuto')" style="cursor: not-allowed"> {{ $t('Pages.Content.Ads.AutomatedAds')}} </label>
            <label><input class="uk-radio" type="radio" name="pre-roll" value="SPECIFIC_ADS" v-model="preRollType" @click="checkPreRollType()"> {{ $t('Pages.Content.Ads.SpecificAds')}} </label>
      </div>
      <div v-if="inputPreRollAds.length>0">
        <ul  v-for="(preRollAd, i) in inputPreRollAds" :key="i" class="uk-list uk-list-disc uk-text-meta uk-margin-left">
            <li>{{ preRollAd.title }}</li>
          </ul>
      </div>
      </div>
    </div>
    <div class="uk-width-1-2 uk-margin-left uk-margin-top">
      <h4 class="uk-text-left uk-text-bold">{{ $t('Pages.Content.Ads.PostRoll') }}</h4>
       <div class="uk-text-left">
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label><input class="uk-radio" type="radio" name="post-roll" value="DISABLED" v-model="postRollType"> {{ $t('Pages.Content.Ads.DisableAds')}} </label>
            <label v-if="content.type==='VOD'"><input class="uk-radio" type="radio" name="post-roll" value="AUTO" v-model="postRollType"> {{ $t('Pages.Content.Ads.AutomatedAds')}} </label>
            <label v-else><input class="uk-radio" type="radio" name="pre-roll"  disabled value="AUTO" :uk-tooltip="$t('Pages.Content.Ads.NotSupportedAuto')" style="cursor: not-allowed"> {{ $t('Pages.Content.Ads.AutomatedAds')}} </label>
            <label><input class="uk-radio" type="radio" name="post-roll" value="SPECIFIC_ADS" v-model="postRollType" @click="checkPostRollType()"> {{ $t('Pages.Content.Ads.SpecificAds')}} </label>
          </div>
          <div v-if="inputPostRollAds.length>0">
            <ul  v-for="(postRollAd, i) in inputPostRollAds" :key="i" class="uk-list uk-list-disc uk-text-meta uk-margin-left">
              <li>{{ postRollAd.title }}</li>
            </ul>
          </div>
      </div>
    </div>
    <hr class="uk-margin-top uk-width-1-2" />
      <div class="uk-text-center uk-width-1-2">
        <button  v-if="!loading" class="uk-button uk-button-primary" type="button" @click="updateContent()">{{ $t( 'Actions.Save' ) }}</button>
        <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
      </div>
    <div v-if="showPreRollAdPicker" id="pre-roll-ad-picker-modal" container="#content-pre-post-roll" uk-modal="bg-close: false; esc-close: false;">
      <div class="uk-modal-dialog uk-modal-body uk-width-3-4">
        <ModalAdPicker :button-text="this.$t('Actions.Select')" ad-type="Video Ad" :ads-list="inputPreRollAds" @cancel="cancelAdModal()" @selectAd="selectedAds( $event )"/>
      </div>
    </div>
    <div v-if="showPostRollAdPicker" id="post-roll-ad-picker-modal" container="#content-pre-post-roll" uk-modal="bg-close: false; esc-close: false;">
        <div class="uk-modal-dialog uk-modal-body uk-width-3-4">
          <ModalAdPicker :button-text="this.$t('Actions.Select')" ad-type="Video Ad" :ads-list="inputPostRollAds" @cancel="cancelAdModal()" @selectAd="selectedAds( $event )"/>
        </div>
    </div>

</div>
</template>

<script>
import { ContentAdTypes } from '@/utils/enums';
import Notification from '@/components/util/Notification';
import { isAdBlocker } from '@/utils/adBlocker';

export default {
  name: 'ContentAds',
  components: {
    ModalAdPicker: () => import('@/components/modals/ModalAdPicker.vue'),
  },
  props: {
    content: { type: Object, required: true }
  },
  data() {
    const { postRollAds, preRollAds } = this.content;
    return {
      detectChange: false,
      formSignature: '',
      formChanged: false,
      preRollType: '',
      postRollType: '',
      showPreRollAdPicker: false,
      showPostRollAdPicker: false,
      inputPreRollAds: preRollAds || [],
      inputPostRollAds: postRollAds || [],
      isPreRoll: false,
      isPostRoll: false,
      loading: false,
      isAdBlockerEnabled: false,
      inputAds: {
        postRoll: { mode: this.preRollType, ads: postRollAds },
        preRoll: { mode: this.postRollType, ads: preRollAds }
      },
      preRollEvent: false,
      postRollEvent: false
    }
  },
  watch: {
    content: {
        handler ( newVal ) {
          if ( newVal ) {
            const { preRollAdsAuto, postRollAds, postRollAdsAuto, preRollAds } = newVal;
            this.preRollType = preRollAdsAuto===true ? ContentAdTypes.AUTO : !preRollAdsAuto && preRollAds?.length > 0 ? ContentAdTypes.SPECIFIC_ADS : ContentAdTypes.DISABLED;
            this.postRollType= postRollAdsAuto===true ? ContentAdTypes.AUTO : !postRollAdsAuto && postRollAds?.length > 0 ? ContentAdTypes.SPECIFIC_ADS : ContentAdTypes.DISABLED;
            this.inputAds = {
                postRoll: { mode: this.postRollType, ads: postRollAds },
                preRoll: { mode: this.preRollType, ads: preRollAds }        
            };
            this.inputPreRollAds = preRollAds || [];
            this.inputPostRollAds = postRollAds || [];
          }
          process.nextTick(() => {
            this.formSignature = this.getSignature()
            this.formChanged = false
            this.detectChange = true
          })
        },
        deep: true,
        immediate: true
    },
    async preRollType () {
      this.isPreRoll = true;
      this.isPostRoll = false;
      if ( this.preRollType === ContentAdTypes.SPECIFIC_ADS && this.preRollEvent) {
        this.showPreRollAdPicker = true;
        if ( await isAdBlocker() )
          Notification.showNotification( this.$t( 'Pages.Advertising.Notifications.AdBlockerNotice' ), this.$t( 'Pages.Advertising.Notifications.AdBlockerMessage' ), 'error' );
        setTimeout( () => {
          window.UIkit.modal('#pre-roll-ad-picker-modal').show();
        }, 100 );
      } else
        this.inputPreRollAds = this.content?.preRollAds;
      this.validateAndSetFormUpdate()
    },
    async postRollType () {
      this.isPreRoll = false;
      this.isPostRoll = true;

      if ( this.postRollType === ContentAdTypes.SPECIFIC_ADS && this.postRollEvent ) {
        this.showPostRollAdPicker = true;
        if ( await isAdBlocker() )
          Notification.showNotification( this.$t( 'Pages.Advertising.Notifications.AdBlockerNotice' ), this.$t( 'Pages.Advertising.Notifications.AdBlockerMessage' ), 'error' );
        setTimeout( () => {
          window.UIkit.modal('#post-roll-ad-picker-modal').show();
        }, 100 );
      } else 
        this.inputPostRollAds = this.content?.postRollAds;
      this.validateAndSetFormUpdate()
    },
    inputAds: {
      handler () {
        this.validateAndSetFormUpdate()
      },
      deep: true,
      immediate: true
    }
},
methods: {
    getSignature () {
      const signObj = Object.assign({ preRollType: this.preRollType, postRollType: this.postRollType }, this.inputAds)
      return JSON.stringify(signObj)
    },
    validateAndSetFormUpdate () {
      if (this.detectChange && this.formSignature) {
        this.$nextTick(() => {
          const newSignature = this.getSignature()
          if (newSignature !== this.formSignature) {
            this.formChanged = true
            this.$emit( 'formChanged', this.formChanged)
          } else {
            this.formChanged = false
            this.$emit( 'formChanged', this.formChanged)
          }
        })
      }
    },
    async checkPreRollType () {
      this.isPreRoll = true;
      this.isPostRoll = false;
      this.preRollEvent = true;
      if ( this.preRollType === ContentAdTypes.SPECIFIC_ADS ) {
        this.showPreRollAdPicker = true;
        if ( await isAdBlocker() )
          Notification.showNotification( this.$t( 'Pages.Advertising.Notifications.AdBlockerNotice' ), this.$t( 'Pages.Advertising.Notifications.AdBlockerMessage' ), 'error' );
        setTimeout( () => {
          window.UIkit.modal('#pre-roll-ad-picker-modal').show();
        }, 100 );
      }
    },
    async checkPostRollType () {
      this.isPreRoll = false;
      this.isPostRoll = true;
      this.postRollEvent = true;
      if ( this.postRollType === ContentAdTypes.SPECIFIC_ADS ) {
        this.showPostRollAdPicker = true;
        if ( await isAdBlocker() )
          Notification.showNotification( this.$t( 'Pages.Advertising.Notifications.AdBlockerNotice' ), this.$t( 'Pages.Advertising.Notifications.AdBlockerMessage' ), 'error' );
        setTimeout( () => {
          window.UIkit.modal('#post-roll-ad-picker-modal').show();
        }, 100 );
      }
    },
    cancelAdModal () {
      if(this.isPreRoll ) {
       this.inputPreRollAds = this.content?.preRollAds;
      }
      else if(this.isPostRoll ) {
        this.inputPostRollAds = this.content?.postRollAds;
      }
      else {
        this.inputPostRollAds = [];
        this.inputPreRollAds = [];
      }
      this.closeAdModal();
    },
    closeAdModal () {
      if (this.isPreRoll ) {
        window.UIkit.modal('#pre-roll-ad-picker-modal').hide();
        setTimeout( function() {
        this.showPreRollAdPicker = false;
        }, 10 );
      }
      if(this.isPostRoll ) {
        window.UIkit.modal('#post-roll-ad-picker-modal').hide();        
        setTimeout( function() {
        this.showPostRollAdPicker = false;
        }, 10 );
      }
    },
    async selectedAds ( ads ) {
    var uniqAds = [...new Set(ads.map(({id}) => id))].map(e => ads.find(({id}) => id == e));
      if ( this.isPreRoll  ) {
        this.inputAds.preRoll = {
            mode: this.preRollType,
            ads: uniqAds
        }
        this.inputPreRollAds = uniqAds;
      }
      if ( this.isPostRoll  ) {
        this.inputAds.postRoll = {
            mode: this.postRollType,
            ads: uniqAds
        }
        this.inputPostRollAds = uniqAds;
      }
      this.closeAdModal();
    },
    async updateContent() {
      this.loading = true;
      const response = await this.$store.dispatch('updateContent', {
        input: { 
          content: this.content?.id,
          ads: {
            preRoll: {
              mode: this.preRollType === ContentAdTypes.SPECIFIC_ADS && this.inputAds?.preRoll?.ads?.length === 0 ? ContentAdTypes.DISABLED : this.preRollType,
              ads: this.inputAds?.preRoll?.ads.map( ad => ad.id ) 
            },
            postRoll: {
              mode: this.postRollType === ContentAdTypes.SPECIFIC_ADS && this.inputAds?.postRoll?.ads?.length === 0 ? ContentAdTypes.DISABLED : this.postRollType,
              ads: this.inputAds?.postRoll?.ads.map( ad => ad.id )
            }
          } 
        }
      });
      this.loading = false;
      this.isPreRoll = false;
      this.isPostRoll = false;
      this.inputAds = {
        postRoll: { mode: this.postRollType, ads: this.content?.postRollAds },
        preRoll: { mode: this.postRollType, ads: this.content?.preRollAds }  
      } 
      if ( response?.updateContentInfo?.content ) {
        this.$nextTick(() => {
            this.formSignature = this.getSignature()
            this.formChanged = false
        })
        Notification.showNotification(this.$t( 'Generic.Messages.Successful'),this.$t("Pages.Content.Ads.UpdateContent"));
      } else
        Notification.showNotification(this.$t("Pages.Content.ContentUpdateError"), response, 'error');
    }
  }
}
</script>

<style scoped>
.uk-checkbox:disabled, .uk-radio:disabled {
  background-color:#a1a7ad;
}
</style>