<template>
    <div id="content-permissions">
        <div class="uk-text-left">  
            <div class="uk-flex-inline uk-flex-middle uk-margin-bottom" v-if="featureSupport && featureSupport.appFencing && isAnyAdmin">
                <h3 class="uk-text-left uk-margin-remove"> {{ $t('Pages.Content.ContentPermissions.ApplicationPermissions') }} </h3>
                <span class="uk-text-danger uk-margin-small-left" v-if="formChanged">{{$t('Generic.Labels.Unsaved')}} <br> </span>
            </div>
            <div v-if="featureSupport && featureSupport.appFencing && isAnyAdmin" class="uk-flex uk-width-2-3">
                <div class="uk-width-1-2 uk-text-left uk-margin-small-right">
                    <label class="uk-text-meta"> {{ $t('Pages.Content.ContentPermissions.AllowedApps') }} </label>
                    <br>
                    <form class="uk-search uk-search-default uk-width-xlarge uk-margin-small-top">
                        <div>
                            <input class="uk-input uk-text-meta uk-text-light" type="text" v-model="allowedApplication" @keyup="openDropdown" :placeholder="$t( 'Pages.Content.ContentPermissions.SelectApps' )" uk-toggle="target:.select">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="triangle-down" />
                        </div>
                        <div class="uk-overflow-auto uk-height-medium select uk-padding-remove" id="select" uk-dropdown="mode: hover; pos: bottom-justify; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 100;flip: true">
                            <ul class="uk-list uk-list-striped">
                                <li class="uk-text-left uk-text-truncate" v-for="application in searchApplicationResults" :key="application.id" :value="application.id" @click="addAllowedApplication(application)">{{ application.name }}</li>
                            </ul>
                        </div>
                    </form>
                    <div class="uk-height-max-small uk-overflow-auto">
                        <div v-for="(tag,index) in form.allowedApplications" :key="index" class="tag-item uk-margin-small-top uk-margin-small-right uk-overflow-hidden" style="max-width:95%">
                            <div class="label uk-text-truncate uk-width-auto" @click="$emit('click')">
                                <span class="tag-text label uk-margin-small-left uk-margin-small-right" :uk-tooltip="tag.name" style="max-width:90%"> {{ tag.name }} </span>
                            </div>
                                <span class="close uk-margin-auto-vertical uk-margin-small-right" style="cursor: pointer;" uk-icon="icon: close;ratio: 0.8" @click="deleteAllowedApplication(tag)"></span>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-2 uk-text-left">
                    <label class="uk-text-meta"> {{ $t('Pages.Content.ContentPermissions.BlockedApps') }} </label>
                    <br>
                    <form class="uk-search uk-search-default uk-width-xlarge uk-margin-small-top">
                        <div>
                            <input class="uk-input uk-text-meta uk-text-light" type="text" v-model="denyApplication" @keyup="openBlockApplicationDropdown" :placeholder="$t( 'Pages.Content.ContentPermissions.SelectApps' )" uk-toggle="target: #application-select">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="triangle-down" />
                        </div>
                        <div class="uk-overflow-auto uk-height-medium select uk-padding-remove" id="application-select" uk-dropdown="mode: hover; pos: bottom-justify; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 100;flip: true">
                            <ul class="uk-list uk-list-striped">
                                <li class="uk-text-left uk-text-truncate" v-for="application in searchDeniedApplicationResults" :key="application.id" :value="application.id" @click="addBlockedApplication(application)">{{ application.name }}</li>
                            </ul>
                        </div>
                    </form>
                    <div class="uk-height-max-small uk-overflow-auto">
                        <div v-for="(tag,index) in form.deniedApplications" :key="index" class="tag-item uk-margin-small-top uk-margin-small-right">
                            <div class="label" @click="$emit('click')">
                                <span class="tag-text label uk-margin-small-left uk-margin-small-right" :uk-tooltip="tag.name"> {{ tag.name }} </span>
                                <span class="close uk-margin-auto-vertical uk-margin-small-right" style="cursor: pointer;" uk-icon="icon: close;ratio: 0.8" @click="deleteDeniedApplication(tag)"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h3 class="uk-text-left"> {{ $t('Pages.Content.ContentPermissions.LocationPermissions') }} </h3>
            <div class="uk-flex uk-width-2-3">
                <div class="uk-width-1-2 uk-text-left uk-margin-small-right">
                    <label class="uk-text-meta"> {{ $t('Pages.Content.ContentPermissions.AllowedLocations') }} </label>
                    <br>
                    <form class="uk-search uk-search-default uk-width-xlarge uk-margin-small-top">
                        <div>
                            <input class="uk-input uk-text-meta uk-text-light" type="text" v-model="allowedLocation" @keyup="openLocationDropdown" :placeholder="$t( 'Pages.Content.ContentPermissions.SelectLocations' )" uk-toggle="target: #location-select">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="triangle-down" />
                        </div>
                        <div class="uk-overflow-auto uk-height-medium uk-padding-remove" id="location-select" uk-dropdown="mode: hover; pos: bottom-justify; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 100;flip: true">
                            <ul class="uk-list uk-list-striped">
                                <li class="uk-text-left uk-text-truncate" v-for="location in searchLocationResults" :key="location.code" :value="location.name"  @click="addAllowedLocation(location)">{{ location.name }}</li>
                            </ul>
                        </div>
                    </form>
                    <div class="uk-height-max-small uk-overflow-auto">
                        <div v-for="(location,index) in form.allowedLocations" :key="index" class="tag-item uk-margin-small-top uk-margin-small-right">
                            <div class="label" @click="$emit('click')">
                                <span class="tag-text label uk-margin-small-left uk-margin-small-right" :uk-tooltip="location"> {{ location }} </span>
                                <span class="close uk-margin-auto-vertical uk-margin-small-right" style="cursor: pointer;" uk-icon="icon: close;ratio: 0.8" @click="deleteAllowedLocation(location)"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-2 uk-text-left uk-margin-small-right">
                    <label class="uk-text-meta"> {{ $t('Pages.Content.ContentPermissions.BlockedLocations') }} </label>
                    <br>
                    <form class="uk-search uk-search-default uk-width-xlarge uk-margin-small-top">
                        <div>
                            <input class="uk-input uk-text-meta uk-text-light" type="text" v-model="deniedLocation" @keyup="openBlockLocationDropdown" :placeholder="$t( 'Pages.Content.ContentPermissions.SelectLocations' )" uk-toggle="target: #block-location-select">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="triangle-down" />
                        </div>
                        <div class="uk-overflow-auto uk-height-medium uk-padding-remove" id="block-location-select" uk-dropdown="mode: hover; pos: bottom-justify; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 100;flip: true">
                            <ul class="uk-list uk-list-striped">
                                <li class="uk-text-left uk-text-truncate" v-for="location in searchDeniedLocationResults" :key="location.code" :value="location.name" @click="addDeniedLocation(location)">{{ location.name }}</li>
                            </ul>
                        </div>
                    </form>
                    <div class="uk-height-max-small uk-overflow-auto">
                        <div v-for="(location,index) in form.deniedLocations" :key="index" class="tag-item uk-margin-small-top uk-margin-small-right">
                            <div class="label" @click="$emit('click')">
                                <span class="tag-text label uk-margin-small-left uk-margin-small-right" :uk-tooltip="location"> {{ location }} </span>
                                <span class="close uk-margin-auto-vertical uk-margin-small-right" style="cursor: pointer;" uk-icon="icon: close;ratio: 0.8" @click="deleteDeniedLocation(location)"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="uk-margin-top uk-width-2-3" />
            <div class="uk-width-2-3 uk-text-center">
                <button v-if="!spinner" class="uk-button uk-button-primary" @click="savePermissions">{{ $t('Actions.Save') }}</button>
                <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import countries from '@/utils/countries.json';
import Notification from '@/components/util/Notification';
import { Permissions } from '@/utils/enums';

export default {
    name: 'ContentPermissions',
    props: {
        content: { type: Object, required: true }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.isAnyAdmin) {
                this.fetchApps();
            }
        })
        window.UIkit.util.on(document, 'show', '#select', async () => {
            document.getElementById('select').scrollTop = 0
        })
        window.UIkit.util.on(document, 'show', '#application-select', async () => {
            document.getElementById('application-select').scrollTop = 0
        })
        window.UIkit.util.on(document, 'show', '#location-select', async () => {
            document.getElementById('location-select').scrollTop = 0
        })
        window.UIkit.util.on(document, 'show', '#block-location-select', async () => {
            document.getElementById('block-location-select').scrollTop = 0
        })
    },
    data () {
        return {
            form: {
                allowedApplications: this.content?.appRestrictions?.allowOnly || [],
                deniedApplications: this.content?.appRestrictions?.deny || [],
                allowedLocations: this.content?.geoRestrictions?.allowOnly || [],
                deniedLocations: this.content?.geoRestrictions?.deny || [],
            },
            allowedApplication: '',
            denyApplication: '',
            allowedLocation: '',
            deniedLocation: '',
            countries,
            spinner: false,
            formChanged: false,
            detectChange: false,
            formSignature: '',
            orgId: this.$route.params.id
        }
    },
    computed: {
        ...mapState({
            applications: state => state.venom.org?.orgApps?.nodes || [],
            featureSupport: state => state.venom.org.options?.featureSupport,
            viewer: state => state.venom.org.viewer
        }),
        isAdmin () {
            return this.viewer?.role === Permissions.ADMIN ? true : false
        },
        isAnyAdmin () { //Admin to any folder
            if (this.isAdmin) return true
            else {
                let isAdmin = false
                for (const perm of this.viewer?.permissions) {
                if (Permissions.ADMIN === perm.type) {
                    isAdmin = true
                    break
                }
                }
                return isAdmin
            }
        },
        applicationList () {
            const excludeApps = [ ...this.form.allowedApplications, ...this.form.deniedApplications ]
            return this.applications?.filter( app => !excludeApps.find( excludedApp => excludedApp.id === app.id ) );
        },
        locationsList () {
            const excludeLocations = [ ...this.form.allowedLocations, ...this.form.deniedLocations ]
            return this.countries?.filter( loc => !excludeLocations.find( excludeLocation => excludeLocation === loc.name ) );
        },
        searchApplicationResults() {
            if (this.allowedApplication.length === 0 ) return this.applicationList;
            return this.applicationList.filter(col => {
                return col.name.toLowerCase().match(this.allowedApplication.toLowerCase());
            });
        },
        searchLocationResults() {
            if (this.allowedLocation.length === 0 ) return this.locationsList;
            return this.locationsList.filter(col => {
                return col.name.toLowerCase().match(this.allowedLocation.toLowerCase());
            });
        },
        searchDeniedApplicationResults() {
            if (this.denyApplication.length === 0 ) return this.applicationList;
            return this.applicationList.filter(col => {
                return col.name.toLowerCase().match(this.denyApplication.toLowerCase());
            });
        },
        searchDeniedLocationResults() {
            if (this.deniedLocation.length === 0 ) return this.locationsList;
            return this.locationsList.filter(col => {
                return col.name.toLowerCase().match(this.deniedLocation.toLowerCase());
            });
        },
        // checkForChanges () {
        //     if()
        // },
    },
    methods: {
        getSignature () {
            return JSON.stringify(this.form)
        },
        async fetchApps () {
            await this.$store.dispatch('fetchAllOrgApps', { orgId: this.orgId })
            this.$nextTick(() => {
                this.formSignature = this.getSignature()
                this.formChanged = false
                this.detectChange = true
            })
        },
        addAllowedApplication ( app ) {
            this.form.allowedApplications.push({ name: app?.name, id:app?.id});
            window.UIkit.dropdown('#select').hide(0);
        },
        addBlockedApplication ( app ) {
            this.form.deniedApplications.push({ name: app?.name, id:app?.id});
            window.UIkit.dropdown('#application-select').hide(0);
        },
        openDropdown () {
            window.UIkit.dropdown('#select').show();
        },
        openBlockApplicationDropdown () {
            window.UIkit.dropdown('#application-select').show();
        },
        openLocationDropdown () {
            window.UIkit.dropdown('#location-select').show();
        },
        openBlockLocationDropdown () {
            window.UIkit.dropdown('#block-location-select').show();
        },
        async savePermissions () {
            this.spinner = true;
            await this.$store.dispatch('updateContentPermissions',{ content: this.content.id, appRestrictions: { allowOnly: this.form.allowedApplications.map( app => app.id ) , deny: this.form.deniedApplications.map( app => app.id )},
            geoRestrictions: { allowOnly: this.form.allowedLocations, deny: this.form.deniedLocations } });
            this.spinner = false;
            this.$nextTick(() => {
                this.formSignature = this.getSignature()
                this.formChanged = false
                this.$emit( 'formChanged', this.formChanged)

            })
            Notification.showNotification(this.$t('Pages.Content.ContentPermissions.Successful'), this.$t('Pages.Content.ContentPermissions.PermissionAddedNotification'));
        },
        addAllowedLocation ( location ) {
            this.form.allowedLocations.push(location.name);
            window.UIkit.dropdown('#location-select').hide(0);
        },
        addDeniedLocation ( location ) {
            this.form.deniedLocations.push(location.name);
            window.UIkit.dropdown('#block-location-select').hide(0);
        },
        deleteAllowedApplication ( app ) {
            const index = this.form.allowedApplications.findIndex(application =>  application.id === app.id )
            this.form.allowedApplications.splice(index,1);
        },
        deleteDeniedApplication ( app ) {
            const index = this.form.deniedApplications.findIndex(application =>  application.id === app.id )
            this.form.deniedApplications.splice(index,1);
        },
        deleteAllowedLocation ( location ) {
            const index = this.form.allowedLocations.findIndex(loc =>  loc === location )
            this.form.allowedLocations.splice(index,1);
        },
        deleteDeniedLocation ( location ) {
            const index = this.form.deniedLocations.findIndex(loc =>  loc === location )
            this.form.deniedLocations.splice(index,1);
        },
    },
    watch: {
        form: {
            handler () {
                if (this.detectChange && this.formSignature) {
                    this.$nextTick(() => {
                        const newSignature = this.getSignature()
                        if (newSignature !== this.formSignature) {
                            this.formChanged = true
                            this.$emit( 'formChanged', this.formChanged)
                        } else {
                            this.formChanged = false
                            this.$emit( 'formChanged', this.formChanged)
                        }
                    })
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
.tag-text {
    margin-left: 3px;
}
</style>